import {
  SystemSetup,
  getBaseSchemaPropertyTemplate,
  getLimaSchemaPropertyTemplate,
  getMofinSchemaPropertyTemplate
} from "../../../../types";
import { SystemSetupTemplates } from "../types";

export const propertyTemplates: SystemSetupTemplates = {
  [SystemSetup.Elphi]: getBaseSchemaPropertyTemplate,
  [SystemSetup.Mofin]: getMofinSchemaPropertyTemplate,
  [SystemSetup.Lima]: getLimaSchemaPropertyTemplate
};
