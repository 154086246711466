import { aggregationFieldTemplate } from "../../../../../../types/aggregations";
import {
  BaseSchemaProperty,
  baseAppraisalType,
  baseConditionRating,
  baseConstructionBudgetAssessment,
  baseLeaseStatus,
  basePropertyType
} from "../../../../../../types/entities/base";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  StateCode,
  StateName
} from "../../../../../../types/entities/location.types";
import { PortfolioTemplateType } from "../../../../../../types/entities/portfolioTemplate.types";
import {
  AppraisalValueDerivationType,
  BillingFrequencyType,
  InspectionOrderType,
  LeaseStrategyType,
  PropertyLoanPurpose,
  PropertyRightsOwnershipType,
  PropertyZoningComplianceRatingType
} from "../../../../../../types/entities/property.enums.types";
import { BaseFields } from "../types";

export const getBaseSchemaPropertyTemplate = (): Omit<
  BaseSchemaProperty,
  BaseFields
> => {
  const calculatedFieldTelpate = aggregationFieldTemplate();
  const unitData = {
    AdjustedRentAmount: "",
    LeaseExpirationDate: "",
    LeaseStatusType: "" as OptionsOf<typeof baseLeaseStatus>,
    MonthlyLeaseRentAmount: "",
    MonthlyMarketRentAmount: "",
    RentReportConfidenceScore: "",
    UnitName: ""
  };

  const unitAggregationData = {
    AdjustedRentAmount: calculatedFieldTelpate
  };

  return {
    Address: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      CountyName: "",
      FullAddressName: "",
      LegalDescription: "",
      PostalCode: "",
      PropertyBlockIdentifier: "",
      PropertyIdentifier: "",
      PropertyLotIdentifier: "",
      PropertySubdivisionIdentifier: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    AllocatedLoanAmount: "",
    AllocatedRDSRRatePercent: "",
    AnnualHomeownersAssociationFeeAmount: "",
    AnnualTaxesAmount: "",
    Appraisal: {
      AppraisalCompletedDate: "",
      AppraisalDate: "",
      AppraisalEffectiveDate: "",
      AppraisalExpirationDate: "",
      AppraisalForm1007RequiredIndicator: "" as BooleanSelectType,
      AppraisalOrderedDate: "",
      AppraisalType: "" as OptionsOf<typeof baseAppraisalType>,
      AppraisalValueDerivationType: "" as AppraisalValueDerivationType,
      ConditionRatingType: "" as OptionsOf<typeof baseConditionRating>,
      PropertyAccessInformation: "",
      PropertyAreaValue: "",
      PropertyBuiltYear: "",
      PropertyStructureBuiltYear: "",
      PropertyValuationAmount: "",
      PropertyZoningComplianceRatingType:
        "" as PropertyZoningComplianceRatingType,
      PropertyZoningDescription: "",
      SpecificZoningClassification: "",
      SubjectToPropertyValuationAmount: "",
      TotalBathroomCount: "",
      TotalBedroomCount: ""
    },
    AppraisalManagementCompany: {
      AppraisalManagementCompanyName: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      RepresentativeFullName: ""
    },
    AssignmentFeeAmount: "",
    BorrowerRenovationCostsAfterPurchaseAmount: "",
    CapitalExpenditureAmount: "",
    CityAnnualTaxAmount: "",
    CityTaxBillingFrequencyType: "" as BillingFrequencyType,
    CityTaxDueDate: "",
    CityTaxingAuthority: "",
    ClosingCostsAmount: "",
    CondoWarrantableIndicator: "" as BooleanSelectType,
    ConstructionBudgetAssessment: "" as OptionsOf<
      typeof baseConstructionBudgetAssessment
    >,
    ConstructionCostAmount: "",
    ConstructionCostComments: "",
    ConstructionHoldbackAmount: "",
    ContractClosingDate: "",
    CountyAnnualTaxAmount: "",
    CountyTaxBillingFrequencyType: "" as BillingFrequencyType,
    CountyTaxDueDate: "",
    CountyTaxingAuthority: "",
    DeferredMaintenanceAmount: "",
    FinancedUnitCount: "",
    FloodInsurance: {
      InsurancePremiumAmount: "",
      InsurancePremiumOutstandingAmount: "",
      NextPaymentDueDate: "",
      MonthlyInsurancePremiumAmount: ""
    },
    GroundLeaseExpirationDate: "",
    InitialFundedAmount: "",
    LeaseExpirationDate: "",
    LeaseStatusType: "" as OptionsOf<typeof baseLeaseStatus>,
    LeaseStrategy: "" as LeaseStrategyType,
    LeasedFinancedUnits: {
      unit0: unitData,
      unit1: unitData,
      unit2: unitData,
      unit3: unitData,
      unit4: unitData,
      unit5: unitData,
      unit6: unitData,
      unit7: unitData,
      unit8: unitData
    },
    NetFundAmount: "",
    NetFundRatePercent: "",
    NetWireAmount: "",
    OriginalPurchaseDate: "",
    OriginalPurchasePriceAmount: "",
    OutstandingLoanPayoffAmount: "",
    PSAAssignmentContractIndicator: "" as BooleanSelectType,
    PSAExpirationDate: "",
    PSAExtensionRequestedIndicator: "" as BooleanSelectType,
    PSASignedIndicator: "" as BooleanSelectType,
    ParcelNumber: "",
    PayoffExpirationDate: "",
    PropertyInsurance: {
      InsurancePremiumAmount: "",
      InsurancePremiumOutstandingAmount: "",
      NextPaymentDueDate: "",
      MonthlyInsurancePremiumAmount: ""
    },
    PropertyInspection: {
      ConstructionAnalystFullName: "",
      InspectionDocumentsReceivedDate: "",
      InspectionInitialDelayReasonStatusType: [],
      InspectionInitialRequestDate: "",
      InspectionOrderProcessedDate: "",
      InspectionOrderReceivedDate: "",
      InspectionOrderType: "" as InspectionOrderType,
      InspectionReportDueDate: "",
      InspectionRequestOrderedDate: "",
      InspectorFullName: ""
    },
    PropertyLoanPurpose: "" as PropertyLoanPurpose,
    PropertyRightsOwnershipType: "" as PropertyRightsOwnershipType,
    PropertyType: "" as OptionsOf<typeof basePropertyType>,
    PurchasePriceAmount: "",
    RentReportConfidenceScorePercent: "",
    RenovationCostAmount: "",
    SheetMetadata: {
      type: "" as PortfolioTemplateType
    },
    SpecialAssessmentAnnualTaxAmount: "",
    SpecialAssessmentTaxBillingFrequencyType: "" as BillingFrequencyType,
    SpecialAssessmentTaxDueDate: "",
    SpecialAssessmentTaxingAuthority: "",
    TaxDueDate: "",
    TotalRentalIncomeAmount: "",
    CityMonthlyTaxAmount: "",
    CountyMonthlyTaxAmount: "",
    SpecialAssessmentMonthlyTaxAmount: "",
    aggregations: {
      AdjustedRentAmount: calculatedFieldTelpate,
      AnnualCapitalExpenditure: calculatedFieldTelpate,
      AnnualTaxAmount: calculatedFieldTelpate,
      AsIsLoanToValueRatePercent: calculatedFieldTelpate,
      CityInitialTaxEscrowAmount: calculatedFieldTelpate,
      CityInitialTaxEscrowMonthCount: calculatedFieldTelpate,
      CostBasisAmount: calculatedFieldTelpate,
      CountyInitialTaxEscrowAmount: calculatedFieldTelpate,
      CountyInitialTaxEscrowMonthCount: calculatedFieldTelpate,
      InitialFloodInsuranceEscrowAmount: calculatedFieldTelpate,
      InitialFloodInsuranceEscrowMonthCount: calculatedFieldTelpate,
      InitialPropertyInsuranceEscrowAmount: calculatedFieldTelpate,
      InitialPropertyInsuranceEscrowMonthCount: calculatedFieldTelpate,
      LeasedFinancedUnits: {
        unit0: unitAggregationData,
        unit1: unitAggregationData,
        unit2: unitAggregationData,
        unit3: unitAggregationData,
        unit4: unitAggregationData,
        unit5: unitAggregationData,
        unit6: unitAggregationData,
        unit7: unitAggregationData,
        unit8: unitAggregationData
      },
      LoanToValueRatePercent: calculatedFieldTelpate,
      LTARVRatePercent: calculatedFieldTelpate,
      LTCRatePercent: calculatedFieldTelpate,
      LTTCRatePercent: calculatedFieldTelpate,
      MinimumRentLossCoverageAmount: calculatedFieldTelpate,
      MonthlyLeaseRentAmount: calculatedFieldTelpate,
      MonthlyMarketRentAmount: calculatedFieldTelpate,
      SpecialAssessmentInitialTaxEscrowAmount: calculatedFieldTelpate,
      SpecialAssessmentInitialTaxEscrowMonthCount: calculatedFieldTelpate,
      TotalCostAmount: calculatedFieldTelpate,
      TotalInsuranceEscrowAmount: calculatedFieldTelpate,
      TotalTaxEscrowAmount: calculatedFieldTelpate,
      ConstructionBudgetHoldbackPercent: calculatedFieldTelpate,
      MonthlyEscrowPaymentsAmount: calculatedFieldTelpate,
      MonthlyInsuranceEscrowAmount: calculatedFieldTelpate,
      MonthlyTaxEscrowAmount: calculatedFieldTelpate
    }
  };
};
