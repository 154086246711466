import { BaseEntity } from "../../db";

export enum ElphiFieldsTemplateType {
  POS_LOAN_PAGE = "posLoanPage",
  POS_TASK_PAGE = "posTaskPage",
  SERVICE_PROVIDERS_MANAGEMENT_PAGE = "serviceProvidersManagementPage"
}

export enum ElphiFieldsTemplateFormat {
  MONEY = "money",
  DATE = "date",
  TEXT = "text",
  NUMBER = "number",
  BOOLEAN = "boolean",
  PERCENTAGE = "percentage"
}

export type FieldsTemplateSelection = {
  [key: string]: {
    order: number;
    formatType?: ElphiFieldsTemplateFormat;
    label?: string;
  };
};

export type ElphiFieldsTemplate = BaseEntity<{
  templateName: string;
  groupByFieldId?: string;
  templateType: ElphiFieldsTemplateType;
  fields: FieldsTemplateSelection;
}>;
