import {
  InsurancePolicyEntityType,
  PropertyInsurancePolicy
} from "../../../../../types/entities/insurance-policy";
import { getBaseInsurancePolicy } from "./insurancePolicy.template";

export const getPropertyInsurancePolicyTemplate =
  (): PropertyInsurancePolicy => {
    return {
      ...getBaseInsurancePolicy(),
      entityType: InsurancePolicyEntityType.Property,
      coverageTypes: [],
      entities: {}
    };
  };
