import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  AggregationServiceLinkFields,
  PropertyFloodCertificateFields
} from "../../../../../../types/entities/integrations";

export const getServiceLinkFloodCertAggregationTempate =
  (): AggregationServiceLinkFields => {
    const thirdPartyTemplate = thirdPartyFieldTemplate();
    const aggregationTemplate = aggregationFieldTemplate();

    return {
      OrderNumber: thirdPartyTemplate,
      OrderStatus: thirdPartyTemplate,
      FloodCheckStatus: thirdPartyTemplate,
      FloodCheckInfo: thirdPartyTemplate,
      FloodCheckDisclaimer: thirdPartyTemplate,
      FloodCheckStatusDescription: thirdPartyTemplate,
      Disclaimer: thirdPartyTemplate,
      DeterminationDate: thirdPartyTemplate,
      FloodMapDate: thirdPartyTemplate,
      FloodZone: thirdPartyTemplate,
      GeoCity: thirdPartyTemplate,
      GeoCounty: thirdPartyTemplate,
      GeoPropertyAddress1: thirdPartyTemplate,
      GeoStateCode: thirdPartyTemplate,
      GeoZipFirst5: thirdPartyTemplate,
      GeoZipLast4: thirdPartyTemplate,
      NFIPLegalCommunityNumber: thirdPartyTemplate,
      NFIPLegalCommunityName: thirdPartyTemplate,
      NFIPMapCommunityName: thirdPartyTemplate,
      NFIPMapCommunityNumber: thirdPartyTemplate,
      NFIPMapPanelNumber: thirdPartyTemplate,
      NFIPPrgStat: thirdPartyTemplate,
      PropertyMSAMD: thirdPartyTemplate,
      RequestedInformation: thirdPartyTemplate,
      ResultCode: thirdPartyTemplate,
      ResultDescription: thirdPartyTemplate,
      ReturnTime: thirdPartyTemplate,
      ReturnDate: thirdPartyTemplate,
      TSDNComments: thirdPartyTemplate,
      SpecialFloodHazardAreaIndicator: aggregationTemplate
    };
  };

export const getServiceLinkFloodCertTempate =
  (): PropertyFloodCertificateFields => {
    return {
      AcceptedAcknowledgmentIndicator: "" as BooleanSelectType,
      CTFFlagIndicator: "",
      FloodFlagIndicator: "",
      LOLFlagIndicator: "",
      ProductCode: "",
      UsersEmailAddress: ""
    };
  };
