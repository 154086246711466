import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { CardOperation } from "../../../../../common-components/card";
import { useServiceProviderHooks } from "../../../../../hooks/rolodexServiceProvider.hooks";
import { CopyIcon } from "../../../../icons";
import { RolodexServiceProviderCard } from "../serviceProviderCard.types";
import { ServiceProviderCard } from "./ServiceProviderCard";

export const ServiceProviderDealCard = (props: {
  data: RolodexServiceProviderCard;
  onToggleRemove: () => void;
  onToggleEdit: () => void;
  onCopyValuesToSystem: () => void;
}) => {
  const { data, onToggleRemove, onToggleEdit, onCopyValuesToSystem } = props;
  const { dealEntities } = data;

  const { getDealEntitiesMap, selectedDeal } = useServiceProviderHooks();
  if (!selectedDeal || !dealEntities) {
    return <></>;
  }
  const selectedDealEntities = dealEntities[selectedDeal.id];
  if (!selectedDealEntities?.entityIds) {
    return <></>;
  }

  const dealEntitiesMap = getDealEntitiesMap(data.entityType);
  const entities = selectedDealEntities.entityIds
    .map((e) => {
      return dealEntitiesMap[e];
    })
    .filter(removeEmpty);

  const operations: CardOperation[] = [
    {
      label: "Edit",
      action: onToggleEdit,
      icon: <EditIcon h={4} w={4} color="gray.600" />
    },
    {
      label: "Remove",
      action: onToggleRemove,
      icon: <DeleteIcon h={4} w={4} color={"gray.600"} />
    },
    {
      label: "Copy values to system",
      action: onCopyValuesToSystem,
      icon: <CopyIcon h={4} w={4} color={"gray.600"} />
    }
  ];
  return (
    <ServiceProviderCard
      data={data}
      cardOperations={operations}
      entities={entities}
    />
  );
};
