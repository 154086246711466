import { FieldType, LabelValue } from "@elphi/types";
import { EMPTY } from "../../../../../constants/common";
import { useServiceProviderHooks } from "../../../../../hooks/rolodexServiceProvider.hooks";
import { FuzzySearchSelect } from "../../../../search/fuzzy/FuzzySearchSelect";
import {
  ServiceProviderOptionsData,
  addProviderOption,
  buildPersonalRolodexOption
} from "../../utils/serviceProvider.utils";
import { useSearchApiHooks } from "./serviceProviderSearch.hooks";
import { ServiceProviderSearchComponentProps } from "./serviceProviderSearch.types";

export const ServiceProviderSearch = (
  props: ServiceProviderSearchComponentProps
) => {
  if (!props.type) {
    return <></>;
  }
  const { serviceProviderState, dealPartyIndividualsSet } =
    useServiceProviderHooks();
  const { searchApi } = useSearchApiHooks(props);
  const { fetchData, response } = searchApi();
  const defaultOption = addProviderOption[props.type];

  const filterOptions = (option: LabelValue & ServiceProviderOptionsData) => {
    return option.value === defaultOption.value || props.filterOptions(option);
  };

  return (
    <FuzzySearchSelect
      searchApi={fetchData}
      defaultOptions={[defaultOption]}
      filterOption={filterOptions}
      searchResponse={response}
      state={serviceProviderState}
      fieldType={props.fieldType || FieldType.SingleSelect}
      currentValue={props.currentValue}
      onSelect={props.onSelect}
      onInputChange={props.onInputChange}
      label={props.label || EMPTY}
      configuration={{
        includeScore: true,
        threshold: 0.4,
        keys: ["name", "firstName", "lastName", "middleName"],
        shouldSort: true
      }}
      buildOption={(p) =>
        buildPersonalRolodexOption({
          provider: p,
          dealPartyIndividuals: dealPartyIndividualsSet
        })
      }
    />
  );
};
