import { ElphiFieldsTemplateResponse } from "@elphi/types";
import { createSlice, EntityState } from "@reduxjs/toolkit";
import { merge } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { fieldsTemplateAdapter } from "./fieldsTemplate.adapter";
import { fieldsTemplateApi } from "./fieldsTemplate.service";

export type fieldsTemplateSliceState = EntityState<ElphiFieldsTemplateResponse>;

const initialState: fieldsTemplateSliceState = {
  ids: [],
  entities: {}
};

export const fieldsTemplateSlice = createSlice({
  name: "fieldsTemplate",
  initialState: fieldsTemplateAdapter.getInitialState(initialState),
  reducers: {
    update: fieldsTemplateAdapter.updateOne,
    remove: fieldsTemplateAdapter.removeOne,
    add: fieldsTemplateAdapter.addOne,
    upsert: fieldsTemplateAdapter.upsertOne,
    upsertMany: fieldsTemplateAdapter.upsertMany,
    removeMany: fieldsTemplateAdapter.removeMany,
    updateMany: fieldsTemplateAdapter.updateMany
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(fieldsTemplateApi)(builder);
    builder.addMatcher(
      fieldsTemplateApi.endpoints.getFieldsTemplate.matchFulfilled,
      (state, { payload }) => {
        const { type } = payload;
        state.ids = [...state.ids, type];
        state.entities = merge({}, state.entities, {
          [type]: payload
        });
      }
    );
  }
});
