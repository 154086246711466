import { mofinPartyBaseFieldsToOmit } from "@elphi/types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  BaseSchemaIndividualPartyFieldSpecs,
  baseSchemaIndividualPartyFieldSpecs
} from "../../../base/spec-files/party/party.fields";
import { mergeSpecs, omitFromSpec } from "../../../utils/formsSpec.utils";
import { mofinCommonPartySpecs } from "./party.fields";

export type FullMofinSchemaIndividualPartyFieldSpecs =
  Partial<BaseSchemaIndividualPartyFieldSpecs>;

export const mofinIndividualPartySpecs: FullMofinSchemaIndividualPartyFieldSpecs =
  mergeSpecs(
    omitFromSpec({
      obj: baseSchemaIndividualPartyFieldSpecs,
      paths: mofinPartyBaseFieldsToOmit
    }),
    mofinCommonPartySpecs
  );

export const fullMofinSchemaIndividualPartySpecs = createSpecWithFieldMeta({
  spec: mofinIndividualPartySpecs
});
