import { pick } from "lodash";
import {
  baseAppraisalType,
  baseConditionRating,
  baseConstructionBudgetAssessment,
  baseFloodInsurancePremiumPayment,
  baseLeaseStatus,
  basePropertyType
} from "../../base/property/baseProperty.enumeration";

export const limaFloodInsurancePremiumPayment = {
  ...pick(baseFloodInsurancePremiumPayment, ["PaidInFull"]),
  PaidAfterClosing: "New Construction - Paid After Closing"
} as const;

export const limaPropertyType = {
  ...pick(basePropertyType, ["SingleFamily", "Townhome", "Condominium"]),
  N24Unit: "2-4 Unit",
  N59UnitMultifamily: "5-9 Unit Multifamily"
} as const;

export const limaLeaseStatus = {
  ...pick(baseLeaseStatus, ["Vacant"]),
  StableLeased: "Stable Leased",
  UnstableLeased: "Unstable Leased"
} as const;

export const limaConstructionBudgetAssessment = {
  ...pick(baseConstructionBudgetAssessment, [
    "StandardRehab",
    "NewConstruction",
    "MidConstruction"
  ]),
  HighRiskProject: "High Risk Project"
} as const;

export const limaAppraisalType = {
  ...pick(baseAppraisalType, [
    "N1004D",
    "N1004",
    "N1025",
    "N1073",
    "N2055",
    "N5UnitNarrative"
  ]),
  HybridExterior: "Hybrid Exterior",
  HybridExteriorWithARV: "Hybrid Exterior With ARV",
  HybridExteriorWithRental: "Hybrid Exterior With Rental",
  HybridInterior: "Hybrid Interior",
  HybridInteriorWithARV: "Hybrid Interior With ARV",
  HybridInteriorWithRental: "Hybrid Interior With Rental",
  LimaOneInternalValuation: "Lima One Internal Valuation"
} as const;

export const limaConditionRating = {
  ...pick(baseConditionRating, [
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "Poor",
    "Fair",
    "Average",
    "Good",
    "Excellent"
  ])
} as const;
