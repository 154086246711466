import { pick } from "lodash";
import {
  baseLoanPartyEntityRole,
  baseLoanPartyIndividualRole
} from "../../base/deal-party/baseDealParty.enumeration";

export const limaLoanPartyEntityRoleType = {
  ...pick(baseLoanPartyEntityRole, ["PrimaryBorrower", "Borrower", "None"]),
  DecideLater: "Decide Later"
} as const;

export const limaLoanPartyIndividualRoleType = {
  ...pick(baseLoanPartyIndividualRole, [
    "Guarantor",
    "Signatory",
    "SignatoryCorporateResolution",
    "None"
  ]),
  Sponsor: "Sponsor",
  Applicant: "Applicant",
  PrimarySponsor: "Primary Sponsor",
  DecideLater: "Decide Later"
} as const;
