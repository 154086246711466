import { Box, Button, Center, Checkbox, Flex, Text } from "@chakra-ui/react";
import {
  ElphiEntityType,
  ElphiFieldsTemplateType,
  FieldType,
  LabelValue,
  RolodexConfiguration,
  RolodexConfigurationFieldType
} from "@elphi/types";
import { filter } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import elphiTheme from "../../../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../../../constants/common";
import { getSpecs } from "../../../../../../forms/schemas/factories/specsFactory";
import { useFieldsTemplateHooks } from "../../../../../../hooks/fieldsTemplate.hooks";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import {
  BaseButtonIconAdd,
  BaseButtonIconDelete
} from "../../../../../button-icon/ButtonIcon";
import StyledInputBuilder from "../../../../../form-builder/InputBuilder";
import ModalContainer from "../../../../../modal-container/ModalContainer";
import { createOptionsFromMap } from "../../../../../utils/formUtils";
import { buildFieldsSpecsOptions } from "../utils/configuration.utils";
import { presetFieldsMap, rolodexFieldTypeOptionMap } from "./modal.utils";

type ServiceProviderInput = {
  id: string;
  input: {
    fieldPath: string;
    isRequired: boolean;
    mappedField?: {
      entityType: ElphiEntityType;
      fieldPath: string;
      templateId: string;
      fieldId: string;
    };
  };
};

export const SelectFieldsModal = (props: {
  isShow: boolean;
  onClose: () => void;
  data: RolodexConfiguration;
  type: RolodexConfigurationFieldType;
}) => {
  const { isShow, onClose, type, data } = props;
  const { updateFieldsConfiguration, updateSystemResponse } =
    useRolodexConfiguration();
  const { filedTempalteState } = useFieldsTemplateHooks();
  const [inputs, setInputs] = useState<ServiceProviderInput[]>([]);

  useEffect(() => {
    setInputs(
      Object.entries(data?.fields?.[type] || {}).map(([id, entry]) => {
        return {
          id,
          input: entry
        };
      })
    );
  }, [data?.fields?.[type]]);

  const specs = getServiceProviderSpecs(type) || {};
  const options = buildFieldsSpecsOptions({
    specs,
    type
  });

  const dealPaths: LabelValue[] | undefined = useMemo(() => {
    const serviceProvidersTeplate =
      filedTempalteState.entities?.[
        ElphiFieldsTemplateType.SERVICE_PROVIDERS_MANAGEMENT_PAGE
      ];
    if (!serviceProvidersTeplate) {
      return undefined;
    }

    const serviceProvidersCopyValueFields = serviceProvidersTeplate.fields;
    return filter(
      serviceProvidersCopyValueFields,
      (x) => x.entityType === ElphiEntityType.deal
    ).map((x) => {
      return {
        label: x.label,
        value: x.originalKey,
        templateId: serviceProvidersTeplate.id,
        fieldId: x.id
      };
    });
  }, [filedTempalteState.entities]);

  const handleOnChange = (r: {
    id: string;
    path: string;
    isRequired: boolean;
    mappedField?: {
      entityType: ElphiEntityType;
      fieldPath: string;
      templateId: string;
      fieldId: string;
    };
  }) => {
    setInputs((prev) =>
      prev.map((input) =>
        input.id === r.id
          ? {
              ...input,
              input: {
                fieldPath: r.path,
                isRequired: r.isRequired,
                mappedField: r.mappedField
              }
            }
          : input
      )
    );
  };

  const handleAddClick = () => {
    setInputs((prev) => [
      ...prev,
      {
        id: uuid(),
        input: {
          fieldPath: EMPTY,
          isRequired: false
        }
      }
    ]);
  };

  const handleDeleteClick = (id: string) => {
    setInputs((prev) => prev.filter((input) => input.id !== id));
  };

  const handleSaveClick = () => {
    updateFieldsConfiguration({
      id: data.id,
      fields: {
        ...data.fields,
        [type]: inputs.reduce((acc, obj) => {
          acc[obj.id] = obj.input;
          return acc;
        }, {})
      }
    }).finally(() => {
      onClose();
    });
  };

  return (
    <ModalContainer
      maxWidth={1000}
      isShow={isShow}
      onCloseModal={onClose}
      header={
        <>
          <Box mt={2}>
            <Text textAlign="center">{data.name}</Text>
          </Box>
          <Box mt={2}>
            <Text textAlign="center">{rolodexFieldTypeOptionMap[type]}</Text>
          </Box>
        </>
      }
      body={
        <>
          <Flex gap={"450px"} ml={"10%"} pb={8}>
            <Text fontSize={16} fontWeight={"bold"}>
              Data Field
            </Text>
            <Text fontSize={16} fontWeight={"bold"}>
              Map to Field
            </Text>
          </Flex>
          {inputs.map((value, index) => {
            const isPreset = !!presetFieldsMap?.[type]?.[value.input.fieldPath];
            return (
              <Flex key={index} w="100%">
                <Box w="50%" p="10px">
                  <StyledInputBuilder
                    isDisabled={isPreset}
                    isReadOnly={isPreset}
                    currentValue={value.input.fieldPath}
                    fieldType={FieldType.SingleSelect}
                    options={options}
                    onChange={(e) =>
                      handleOnChange({
                        id: value.id,
                        path: e.target.value,
                        isRequired: false,
                        mappedField: {
                          entityType: ElphiEntityType.deal,
                          fieldPath: EMPTY,
                          templateId: EMPTY,
                          fieldId: EMPTY
                        }
                      })
                    }
                  />
                </Box>
                <Flex direction={"column"} pl={2}>
                  <Flex direction={"row"}>
                    <Text fontSize={12} fontWeight={"bold"}>
                      Required?
                    </Text>
                  </Flex>
                  <Flex direction={"row"}>
                    <Checkbox
                      isDisabled={isPreset}
                      isReadOnly={isPreset}
                      mr={1}
                      isChecked={value.input.isRequired}
                      key={index}
                      onChange={(e) =>
                        handleOnChange({
                          id: value.id,
                          path: value.input.fieldPath,
                          isRequired: e.target.checked
                        })
                      }
                    />
                    <BaseButtonIconDelete
                      isDisabled={isPreset}
                      onClick={() => handleDeleteClick(value.id)}
                    />
                  </Flex>
                </Flex>
                <Flex w="100%" pl={8}>
                  <Flex w="100%" pr="10px" pl="10px" direction={"column"}>
                    <Text fontSize={12} fontWeight={"bold"}>
                      Entity
                    </Text>
                    <StyledInputBuilder
                      isDisabled={true}
                      isReadOnly={true}
                      currentValue={ElphiEntityType.deal}
                      fieldType={FieldType.SingleSelect}
                      options={createOptionsFromMap(ElphiEntityType)}
                      onChange={() => {}}
                    />
                  </Flex>
                  <Flex w="100%" pr="10px" pl="10px" direction={"column"}>
                    <Text fontSize={12} fontWeight={"bold"}>
                      Field
                    </Text>
                    <StyledInputBuilder
                      currentValue={value.input.mappedField?.fieldPath}
                      fieldType={FieldType.SingleSelect}
                      options={dealPaths}
                      onChange={(e: {
                        target: {
                          value: string;
                          templateId: string;
                          fieldId: string;
                        };
                      }) =>
                        handleOnChange({
                          id: value.id,
                          path: value.input.fieldPath,
                          isRequired: value.input.isRequired,
                          mappedField: {
                            entityType: ElphiEntityType.deal,
                            fieldPath: e.target.value,
                            templateId: e.target.templateId,
                            fieldId: e.target.fieldId
                          }
                        })
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
          <Box>
            <Center>
              <BaseButtonIconAdd onClick={handleAddClick} isDisabled={false} />
            </Center>
          </Box>
        </>
      }
      footer={
        <>
          <Flex>
            <Box p="5px">
              <Button
                isLoading={updateSystemResponse.isLoading}
                onClick={handleSaveClick}
                float="right"
                {...elphiTheme.components.light.button.primary}
              >
                Save
              </Button>
            </Box>
            <Box p="5px">
              <Button
                onClick={onClose}
                float="right"
                {...elphiTheme.components.light.button.primary}
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </>
      }
    />
  );
};

const getServiceProviderSpecs = (type: RolodexConfigurationFieldType) => {
  const fieldsSpecs = getSpecs();
  const serviceProviderSpecs =
    fieldsSpecs[ElphiEntityType.serviceProvider]?.specsParts;
  switch (type) {
    case RolodexConfigurationFieldType.CompanyFields:
      return serviceProviderSpecs?.companyServiceProviderFieldsSpecs;
    case RolodexConfigurationFieldType.BranchFields:
      return serviceProviderSpecs?.branchServiceProviderFieldsSpecs;
    case RolodexConfigurationFieldType.RepresentativeFields:
      return serviceProviderSpecs?.repServiceProviderFieldsSpecs;
    default:
      console.error("Invalid type", type);
      return undefined;
  }
};
