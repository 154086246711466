import { thirdPartyFieldTemplate } from "../../../../../../types/aggregations";
import { AggregationRentRangeFields } from "../../../../../../types/entities/integrations";

export const getRentalDataRentRangeAggregationTempate =
  (): AggregationRentRangeFields => {
    const thirdPartyTemplate = thirdPartyFieldTemplate();
    return {
      confidenceScore: thirdPartyTemplate,
      orderStatus: thirdPartyTemplate,
      errorMessage: thirdPartyTemplate,
      requestId: thirdPartyTemplate
    };
  };
