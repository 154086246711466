import { BaseEntity } from "../../db";

export enum PosPublishStatus {
  Publishing = "publishing",
  Unpublishing = "unpublishing",
  Published = "published",
  Unpublished = "unpublished",
  FailedToPublished = "failedToPublished",
  FailedToUnpublished = "failedToUnpublished"
}

export type PosDealUserParty = BaseEntity<{
  dealId: string;
  partyIds: string[];
  userId: string;
  status?: PosPublishStatus;
}>;
