import { BaseEntity } from "../db";

export enum EmailTemplateProviderType {
  Sendgrid = "sendgrid"
}

export enum EmailTemplateType {
  PosInvitation = "pos-invitation",
  PosResetPassword = "pos-reset-password"
}

export type EmailTemplate = BaseEntity<{
  type: EmailTemplateType;
  providerType: EmailTemplateProviderType;
  providerTemplateId?: string;
  subject?: string;
  html?: string;
  schemaParams?: string[];
  from: string;
  bcc?: string[];
}>;
