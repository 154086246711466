import { pick } from "lodash";
import {
  baseEntityToEntityRelationRoleType,
  baseEntityToIndividualRelationRoleType
} from "../../base/party-relation/basePartyRelation.enumeration";

export const limaEntityToIndividualRelationRoleType = {
  ...pick(baseEntityToIndividualRelationRoleType, [
    "AuthorizedSignatory",
    "CEO",
    "CFO",
    "Director",
    "Executor",
    "GeneralPartner",
    "LegalCounsel",
    "LimitedPartner",
    "Manager",
    "ManagingMember",
    "Member",
    "Partner",
    "President",
    "Principal",
    "Secretary",
    "SoleMember",
    "Treasurer",
    "Trustee",
    "VicePresident"
  ]),
  NonOwnerManagingMember: "Non-Owner Managing Member",
  Owner: "Owner",
  SpecialLimitedPartner: "Special Limited Partner"
} as const;

export const limaEntityToEntityRelationRoleType =
  baseEntityToEntityRelationRoleType;
