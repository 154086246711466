import {
  Box,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure
} from "@chakra-ui/react";
import {
  MaximizeIcon,
  MinimizeIcon,
  MoreHorizontalIcon
} from "../../components/icons";

export type CardOperation = {
  label: string;
  action: () => void;
  icon: JSX.Element;
};

type CardProps = {
  header: string;
  collapseHeight: string | number;
  minimizedView: JSX.Element;
  maximizedView: JSX.Element;
  cardOperations: CardOperation[];
  defaultIsOpen?: boolean;
  borderColor?: string;
};
export const Card = (props: CardProps) => {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: props.defaultIsOpen
  });

  return (
    <Box
      borderWidth={1}
      borderRadius={"8px"}
      boxShadow={"md"}
      h="fit-content"
      p={2}
      width={400}
      overflow={"auto"}
      borderColor={props.borderColor}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Heading as={"h4"} size="md">
          {props.header}
        </Heading>
        <Flex>
          <ExpandClick isOpen={isOpen} onToggle={onToggle} />
          <MoreOptionsMenuClick operations={props.cardOperations} />
        </Flex>
      </Flex>
      <Collapse in={!isOpen}>{props.minimizedView}</Collapse>
      <Collapse
        in={isOpen}
        endingHeight={props.collapseHeight}
        style={{ overflowY: "auto" }}
      >
        {props.maximizedView}
      </Collapse>
    </Box>
  );
};

const ExpandClick = (props: { isOpen: boolean; onToggle: () => void }) => {
  return (
    <IconButton
      minW={"unset"}
      bgColor={"transparent"}
      size="sm"
      aria-label=""
      variant="unstyled"
      icon={
        !props.isOpen ? (
          <MaximizeIcon w={5} h={5} />
        ) : (
          <MinimizeIcon w={5} h={5} />
        )
      }
      onClick={() => {
        props.onToggle();
      }}
    />
  );
};

const MoreOptionsMenuClick = (props: { operations: CardOperation[] }) => {
  return (
    <Menu placement={"bottom-start"}>
      <MenuButton
        ml={1}
        minW={"unset"}
        as={IconButton}
        aria-label=""
        variant="unstyled"
        icon={<MoreHorizontalIcon w={6} h={6} />}
        border={"none"}
        size="sm"
      />
      <MenuList zIndex={9}>
        {props.operations.map((op, index) => (
          <MenuItem key={index} onClick={op.action} icon={op.icon}>
            {op.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
