import { FeatureFlagsFallback } from ".";

export enum DevFeatureFlag {
  ESD_2788_Rolodex_Management = "esd_2788_rolodex_management",
  ESD_2789_Rolodex_End_User = "esd_2789_rolodex_end_user",
  ESD_2826_Organization_Management = "esd_2826_organization_management",
  ESD_2785_Calendar = "esd_2785_calendar",
  ESD_2785_Calendar_Trigger = "esd_2785_calendar_trigger",
  ESD_2996_NotificationCenter = "esd_2996_notification_center",
  ESD_2792_InsurancePolicy = "esd_2792_insurancepolicy",
  Esd_3067_Discussion_Per_Entity = "esd_3067_discussion_per_entity",
  ESD_3283_LoanPASS_integration = "esd_3283_loanpass_integration"
}

export const devFeatureFlagFallback: FeatureFlagsFallback<DevFeatureFlag> = {
  [DevFeatureFlag.ESD_2788_Rolodex_Management]: false,
  [DevFeatureFlag.ESD_2789_Rolodex_End_User]: false,
  [DevFeatureFlag.ESD_2826_Organization_Management]: false,
  [DevFeatureFlag.ESD_2785_Calendar]: false,
  [DevFeatureFlag.ESD_2785_Calendar_Trigger]: false,
  [DevFeatureFlag.ESD_2996_NotificationCenter]: false,
  [DevFeatureFlag.ESD_2792_InsurancePolicy]: false,
  [DevFeatureFlag.Esd_3067_Discussion_Per_Entity]: false,
  [DevFeatureFlag.ESD_3283_LoanPASS_integration]: false
};
