import { merge, omit } from "lodash";
import { BaseSchemaProperty } from "../../../../../../types/entities/base";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  MofinProperty,
  MofinPropertyFields
} from "../../../../../../types/entities/mofin/property";
import { mofinAppraisalValueDerivationType } from "../../../../../../types/entities/mofin/property/mofinProperty.enumerations";
import { DotNestedKeys } from "../../../../../../types/utils/flatten";
import {
  getServiceLinkFloodCertAggregationTempate,
  getServiceLinkFloodCertTempate
} from "../../integrations/property";
import { getBaseSchemaPropertyTemplate } from "../base-schema";
import { BaseFields } from "../types";

export const mofinPropertyBaseFieldsToOmit: DotNestedKeys<
  Partial<BaseSchemaProperty>
>[] = [
  "Appraisal.AppraisalValueDerivationType",
  "Appraisal.AppraisalCompletedDate",
  "Appraisal.AppraisalExpirationDate",
  "Appraisal.PropertyZoningDescription",
  "PropertyInspection"
];

export const getMofinSchemaPropertyTemplate = (): Omit<
  MofinProperty,
  BaseFields
> => {
  const baseDeal = omit(
    getBaseSchemaPropertyTemplate(),
    mofinPropertyBaseFieldsToOmit
  );
  const mofinPropertyFields: MofinPropertyFields = {
    aggregations: {
      Integrations: {
        FloodCertification: {
          ServiceLink: getServiceLinkFloodCertAggregationTempate()
        }
      }
    },
    Integrations: {
      FloodCertification: {
        ServiceLink: getServiceLinkFloodCertTempate()
      }
    },
    Appraisal: {
      AppraisalValueDerivationType: "" as OptionsOf<
        typeof mofinAppraisalValueDerivationType
      >
    },
    PayoffInterestDays: "",
    CanBeRebuiltIndicator: "" as BooleanSelectType
  };

  const mofinProperty = merge(baseDeal, mofinPropertyFields);
  return mofinProperty as Omit<MofinProperty, BaseFields>;
};
