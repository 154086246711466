import { omit } from "lodash";
import { BaseEntity } from "../../../../../types/db";
import { DotNestedKeys } from "../../../../../types/utils/flatten";

const commonFieldsToRemove: DotNestedKeys<BaseEntity<object>>[] = [
  "createdAt",
  "modifiedAt",
  "index"
];

const fieldsToRemoveOnCreate: DotNestedKeys<BaseEntity<object>>[] = [
  ...commonFieldsToRemove,
  "id"
];

const fieldsToRemoveOnUpdate: DotNestedKeys<BaseEntity<object>>[] = [
  ...commonFieldsToRemove
];

export const removeBaseFields = <T extends object>(r: {
  entity: T;
  operationType?: "create" | "update";
}): Partial<T> => {
  const keysToRemove =
    !r.operationType || r.operationType === "create"
      ? fieldsToRemoveOnCreate
      : fieldsToRemoveOnUpdate;

  return omit(r.entity, keysToRemove);
};
