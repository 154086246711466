import {
  DealPartyRelationType,
  LabelValue,
  RolodexServiceProvider,
  ServiceProviderEntityType,
  StatusCode
} from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { buildInsurancePolicyName } from "@elphi/utils/src/print-utils/insurancePolicy.utils";
import { uniq, values } from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import {
  getDealIdentifier,
  getPartyName,
  getPropertyAddress
} from "../components/task/taskPrint.utils";
import { useElphiToast } from "../components/toast/toast.hook";
import { EMPTY, NOT_AVAILABLE } from "../constants/common";
import { RootState } from "../redux/store";
import {
  CopyValuesToSystemRequest,
  RolodexServiceProviderRequest,
  serviceProviderApi,
  serviceProviderSlice
} from "../redux/v2/rolodex";
import { getListFromDictionary } from "../utils/batchUtils";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";
import useDealHooks from "./deal.hooks";
import { useDealPropertyRelationHooks } from "./dealPropertyRelation.hooks";
import { useDealPartyRelationHooks } from "./dealpartyrelation.hooks";
import { useInsurancePolicyHooks } from "./insurance-policy/insurancePolicy.hooks";
import { usePartyHooks } from "./party.hooks";
import { usePropertyHooks } from "./property.hooks";

const useEntitiesHooks = () => {
  const { selectedDeal } = useDealHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const { propertyState } = usePropertyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { partyState } = usePartyHooks();
  const { insurancePolicyState } = useInsurancePolicyHooks();

  const dealPropertyIds = useMemo(() => {
    if (!selectedDeal) {
      return [];
    }

    const propertyIds = uniq(
      Object.values(dealPropertyRelationState.entities)
        .filter((x) => x?.dealId === selectedDeal.id)
        .map((x) => x?.propertyId)
        .filter(removeEmpty)
    );

    return propertyIds;
  }, [
    selectedDeal,
    dealPropertyRelationState.entities,
    propertyState.entities
  ]);

  const dealPartyIds = useMemo(() => {
    if (!selectedDeal) {
      return [];
    }

    const partyIds = uniq(
      Object.values(dealPartyRelationState.entities)
        .filter((x) => x?.dealId === selectedDeal.id)
        .map((x) => x?.partyId)
        .filter(removeEmpty)
    );

    return partyIds;
  }, [selectedDeal, dealPartyRelationState.entities, partyState.entities]);

  const dealInsurancePolicyIds = useMemo(() => {
    if (!selectedDeal) {
      return [];
    }

    const insurancePolicyIds = uniq(
      Object.values(insurancePolicyState.entities)
        .filter((x) => x?.dealIds?.includes(selectedDeal.id))
        .map((x) => x?.id)
        .filter(removeEmpty)
    );

    return insurancePolicyIds;
  }, [selectedDeal, insurancePolicyState.entities]);

  const dealOptions = useMemo(() => {
    if (!selectedDeal) {
      return [];
    }
    return [
      {
        label: getDealIdentifier(selectedDeal),
        value: selectedDeal.id
      }
    ];
  }, [selectedDeal]);

  const partyOptions = useMemo(() => {
    return dealPartyIds.map((x) => {
      const p = partyState.entities[x];
      return {
        label: getPartyName(p),
        value: p?.id || EMPTY
      };
    });
  }, [dealPartyIds, partyState.entities]);

  const insurancePolicyOptions = useMemo(() => {
    return dealInsurancePolicyIds.map((x) => {
      const insurancePolicy = insurancePolicyState.entities[x];
      return {
        label: buildInsurancePolicyName(insurancePolicy) || NOT_AVAILABLE,
        value: insurancePolicy?.id || EMPTY
      };
    });
  }, [insurancePolicyState.entities]);

  const dealPropertyOptions = useMemo(() => {
    return dealPropertyIds.map((x) => {
      const p = propertyState.entities[x];
      return {
        label: getPropertyAddress(p),
        value: p?.id || NOT_AVAILABLE
      };
    });
  }, [dealPropertyIds, propertyState.entities]);

  const optionsMap: Record<ServiceProviderEntityType, LabelValue[]> = {
    [ServiceProviderEntityType.Deal]: dealOptions,
    [ServiceProviderEntityType.Property]: dealPropertyOptions,
    [ServiceProviderEntityType.Party]: partyOptions,
    [ServiceProviderEntityType.InsurancePolicy]: insurancePolicyOptions
  };

  const dealPartyIndividualsSet = useMemo(() => {
    if (!selectedDeal) {
      return new Set<string>();
    }

    return new Set<string>(
      Object.values(dealPartyRelationState.entities)
        .filter(
          (x) =>
            x?.dealId === selectedDeal.id &&
            x.type === DealPartyRelationType.DealIndividual
        )
        .filter(removeEmpty)
        .map((x) => x.partyId)
    );
  }, [selectedDeal, dealPartyRelationState.entities]);

  const getDealEntitiesMap = (type: ServiceProviderEntityType) => {
    switch (type) {
      case ServiceProviderEntityType.Deal:
        if (!selectedDeal) return {};
        return {
          [selectedDeal.id]: {
            id: selectedDeal.id,
            text: selectedDeal.LoanIdentifier
          }
        };
      case ServiceProviderEntityType.Party:
        return dealPartyIds.reduce((p, v) => {
          const pr = partyState.entities[v];
          if (!pr) {
            return p;
          }
          p[v] = {
            id: pr.id,
            text: getPartyName(pr),
            type: pr?.PartyType
          };
          return p;
        }, {});
      case ServiceProviderEntityType.Property:
        return dealPropertyIds.reduce((p, v) => {
          const pr = propertyState.entities[v];
          if (!pr) {
            return p;
          }
          p[v] = {
            id: pr.id,
            text: getPropertyAddress(pr)
          };
          return p;
        }, {});
      case ServiceProviderEntityType.InsurancePolicy:
        return dealInsurancePolicyIds.reduce((p, v) => {
          const insurance = insurancePolicyState.entities[v];
          if (!insurance) {
            return p;
          }
          p[v] = {
            id: insurance.id,
            text: buildInsurancePolicyName(insurance) || NOT_AVAILABLE
          };
          return p;
        }, {});
      default:
        return {};
    }
  };

  return {
    optionsMap,
    dealPartyIndividualsSet,
    getDealEntitiesMap,
    selectedDeal
  };
};

export const useServiceProviderHooks = () => {
  const dispatch = useDispatch();
  const {
    optionsMap,
    dealPartyIndividualsSet,
    getDealEntitiesMap,
    selectedDeal
  } = useEntitiesHooks();
  const { successToast, errorToast } = useElphiToast();
  const [upsertApi, upsertResponse] = serviceProviderApi.useUpsertMutation();
  const [removeDealRelationApi, removeDealRelationApiResponse] =
    serviceProviderApi.useRemoveDealRelationMutation();
  const [removePartyRelationApi, removePartyRelationApiResponse] =
    serviceProviderApi.useRemovePartyRelationMutationMutation();
  const [getByIdApi, getByIdApiResponse] = serviceProviderApi.useLazyGetQuery();
  const [getBatchApi, getBatchResponse] =
    serviceProviderApi.useLazyGetBatchQuery();
  const [updateBatchApi, updateBatchResponse] =
    serviceProviderApi.useBatchUpdateMutation();
  const [searchApi, searchResponse] = serviceProviderApi.useLazySearchQuery();
  const [cascadeDeleteApi, cascadeDeleteResponse] =
    serviceProviderApi.useLazyCascadeDeleteQuery();
  const [getTreeApi, _] = serviceProviderApi.useLazyGetTreeQuery();
  const [copyValuesToSystem, copyValuesToSystemResponse] =
    serviceProviderApi.useCopyValuesToSystemMutation();

  const handleCopyValuesToSystem = async (r: CopyValuesToSystemRequest) => {
    return await copyValuesToSystem(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Copied values to system",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to copy values to system",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const createHandler = async (r: RolodexServiceProviderRequest) => {
    return await upsertApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Rolodex created",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create rolodex",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const updateHandler = async (r: Partial<RolodexServiceProviderRequest>) => {
    return await upsertApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Rolodex updated",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update rolodex",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const removeDealRelationHandler = async (r: {
    companyId: string;
    branchId: string;
    representativeId: string;
    dealId: string;
  }) => {
    return await removeDealRelationApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Deal removed from service provider",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to remove deal from service provider",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const removePartyRelationHandler = async (r: {
    companyId: string;
    branchId: string;
    representativeId: string;
    partyId: string;
  }) => {
    if (!r.partyId) {
      errorToast({
        title: "Failed to remove party relation from service provider",
        description: "Party id is missing"
      });
      return;
    }
    return await removePartyRelationApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Party relation removed from service provider",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to remove party relation from service provider",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const getByIdHandler = async (r: { id: string }) => {
    await getByIdApi(r.id)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const getBatchHandler = async (ids: string[]) => {
    return await getBatchApi(ids)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get service provider batch",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const cascadeDeleteHandler = async (r: {
    id: string;
    preprocess: boolean;
  }) => {
    const { preprocess } = r;
    return await cascadeDeleteApi(r, false)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.OK && !preprocess) {
          successToast({
            title: "Deleted successfully",
            description: "Service provider deleted successfully"
          });
        }
        if (r.status === StatusCode.BadRequest) {
          const op = preprocess ? "delete" : "preprocess";
          errorToast({
            title: `Failed to ${op}`,
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const updateServiceProvidersHandler = async (map?: {
    [id: string]: PartialWithId<RolodexServiceProvider>;
  }) => {
    if (!map) {
      return;
    }
    const providers = getListFromDictionary(map);
    if (!providers.length) {
      return;
    }
    return await updateBatchApi({ providers } as {
      providers: ({
        id: string;
      } & Partial<RolodexServiceProvider>)[];
    })
      .then(responseHandler)
      .then((r) => {
        if (r?.status === StatusCode.OK) {
          successToast({
            title: "service providers update success",
            description: `${r?.data?.batch?.length} service providers updated`
          });
        }
        if (r?.status === StatusCode.BadRequest) {
          errorToast({
            title: "service providers update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      });
  };

  const serviceProviderState = useSelector(
    (state: RootState) => state.serviceProvider
  );

  const setSelectedServiceProvider = (id: string) =>
    dispatch(serviceProviderSlice.actions.selectedId({ id }));

  const resetPreprocessed = () =>
    dispatch(serviceProviderSlice.actions.resetPreprocessed());

  const selectedServiceProvider = useSelector(
    (state: RootState) =>
      (state.serviceProvider.selectedId &&
        state.serviceProvider.entities[state.serviceProvider.selectedId]) ||
      undefined
  );

  const getServiceProviderTreeHandler = async (r: {
    serviceProviderId: string;
  }) => {
    return await getTreeApi({ id: r.serviceProviderId }, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((x) => {
        if (x.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get service provider tree",
            description: x.data.description
          });
        }
        if (x.status === StatusCode.OK) {
          const serviceProviders: RolodexServiceProvider[] = values(
            x?.data?.serviceProviders.entities
          ).filter(removeEmpty);
          return serviceProviders;
        }
      });
  };

  const preprocessed = useSelector(
    (state: RootState) => state.serviceProvider.preprocessed
  );

  const dataRank: FieldsWeight<RolodexServiceProvider> = {
    name: 50,
    firstName: 50,
    lastName: 40,
    middleName: 30
  };

  const rankedSort = (query: string) => {
    return Object.values(serviceProviderState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank);
      const rankB = getRankedData(query, b, dataRank);
      if (rankA < rankB) {
        return 1;
      }
      return -1;
    });
  };

  return {
    optionsMap,
    createHandler,
    updateHandler,
    upsertResponse,
    serviceProviderState,
    setSelectedServiceProvider,
    selectedServiceProvider,
    dealPartyIndividualsSet,
    getDealEntitiesMap,
    selectedDeal,
    removeDealRelationHandler,
    removeDealRelationApiResponse,
    getByIdHandler,
    getByIdApiResponse,
    getBatchHandler,
    getBatchResponse,
    updateServiceProvidersHandler,
    removePartyRelationHandler,
    removePartyRelationApiResponse,
    updateBatchResponse,
    searchApi,
    searchResponse,
    rankedSort,
    dataRank,
    cascadeDeleteHandler,
    cascadeDeleteResponse,
    resetPreprocessed,
    preprocessed,
    getServiceProviderTreeHandler,
    handleCopyValuesToSystem,
    copyValuesToSystemResponse
  };
};
