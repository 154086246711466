import { Status } from "../../common";
import { BaseEntity } from "../../db";
import { ElphiEntityType } from "../elphiEntities.types";
import { ServiceProviderEntityType } from "./rolodex.enums";

export type RolodexEntityFields = {
  [key: string]: {
    fieldPath: string;
    isRequired: boolean;
    mappedField?: {
      entityType: ElphiEntityType;
      fieldPath: string;
      templateId: string;
      fieldId: string;
    };
  };
};

export enum RolodexConfigurationFieldType {
  CompanyFields = "companyFields",
  BranchFields = "branchFields",
  RepresentativeFields = "representativeFields"
}

export type RolodexFields = {
  [P in RolodexConfigurationFieldType]: RolodexEntityFields;
};

export type RolodexConfiguration = BaseEntity<{
  name: string;
  entityType: ServiceProviderEntityType;
  fields: Partial<RolodexFields>;
  status: Status;
  mappedField?: {
    entityType: ElphiEntityType;
    fieldPath: string;
    templateId: string;
    fieldId: string;
  };
}>;
