import { MofinDeal } from "@elphi/types/entities/mofin/deal";
import { hideRental30 } from "../../../../components/application/sections/sections.utils";
import { OnChangeInput } from "../../../../components/form-builder/FormBuilder";
import {
  achElectedIndicator,
  assignmentStatus,
  fundingAgentEmailAddress,
  loanPackageInternationalExecutionIndicator,
  loanPackageNoteReceivedByLenderIndicator,
  loanPackageNoteTrackingNumber,
  loanPackageTrackingNumber,
  mersMin,
  operationsDepartment,
  titleCommitmentAllSubjectPropertiesIncludedIndicator,
  titleCommitmentComments,
  titleCommitmentOwnership,
  titleCommitmentTitleEffectiveDate,
  titleCommitmentTitleExpirationDate,
  titleCommitmentTitlePolicyAmount,
  titleCommitmentTitleVestingType
} from "../../base/inputs/deal.inputs";
import { addRuleToInput, buildSection } from "../../utils/formBuilder.utils";
import {
  achStatus,
  finalTitlePolicyToCustodianDate,
  insuranceMortgageeRequestDate,
  insuranceMortgageeRequestFollowUpDate,
  insuranceMortgageeStatus,
  mERSLoanIndicator,
  receivedFinalTitlePolicyIndicator,
  receivedRecordablesIndicator,
  sentRecordablesToCustodianDate,
  warehouseBankStatusType
} from "../inputs/deal.inputs";
import { mofinSpecs } from "../spec-files/mofinFieldSpecs";
import { MofinSections } from "../types";

const electronicExecutedLoanPackageSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Electronic Executed Loan Package",
    inputs: [
      loanPackageTrackingNumber,
      loanPackageNoteTrackingNumber,
      loanPackageInternationalExecutionIndicator,
      warehouseBankStatusType,
      achElectedIndicator,
      fundingAgentEmailAddress,
      achStatus,
      operationsDepartment
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const assignmentSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Assignment",
    inputs: [mERSLoanIndicator, mersMin, assignmentStatus],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const titleCommitmentSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Title Commitment",
    inputs: [
      titleCommitmentTitleEffectiveDate,
      titleCommitmentTitleExpirationDate,
      titleCommitmentOwnership,
      titleCommitmentTitlePolicyAmount,
      addRuleToInput({
        input: titleCommitmentTitleVestingType,
        state: r.state,
        rules: [{ field: "isHidden", predicate: () => hideRental30(r.state) }]
      }),
      titleCommitmentAllSubjectPropertiesIncludedIndicator,
      titleCommitmentComments
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const physicalExecutedLoanPackageSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Physical Executed Loan Package",
    inputs: [
      receivedRecordablesIndicator,
      sentRecordablesToCustodianDate,
      receivedFinalTitlePolicyIndicator,
      finalTitlePolicyToCustodianDate,
      loanPackageTrackingNumber,
      loanPackageNoteTrackingNumber,
      loanPackageNoteReceivedByLenderIndicator
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

const secondaryMarketSection = (r: {
  state: Partial<MofinDeal>;
  onChange: (v: OnChangeInput) => void;
}) =>
  buildSection({
    state: r.state,
    onChange: r.onChange,
    header: "Secondary Market",
    inputs: [
      insuranceMortgageeStatus,
      insuranceMortgageeRequestDate,
      insuranceMortgageeRequestFollowUpDate
    ],
    fieldSpecs: mofinSpecs.deal.entitySpecs
  });

export const dealSectionBuilders: MofinSections["deal"] = {
  electronicExecutedLoanPackageSection,
  physicalExecutedLoanPackageSection,
  assignmentSection,
  secondaryMarketSection,
  titleCommitmentSection
};
