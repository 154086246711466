import { thirdPartyFieldTemplate } from "../../../../../../types/aggregations";
import { AggregationSiteXFields } from "../../../../../../types/entities/integrations";

export const getPropertyReportSitexAggregationTempate =
  (): AggregationSiteXFields => {
    const thirdPartyTemplate = thirdPartyFieldTemplate();
    return {
      addressLineText: thirdPartyTemplate,
      addressUnitIdentifier: thirdPartyTemplate,
      cityName: thirdPartyTemplate,
      stateCode: thirdPartyTemplate,
      postalCode: thirdPartyTemplate,
      countyName: thirdPartyTemplate,
      assessorParcelNumber: thirdPartyTemplate,
      fips: thirdPartyTemplate,
      orderStatus: thirdPartyTemplate,
      orderCompletedOn: thirdPartyTemplate
    };
  };
