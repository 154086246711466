import { Box, Center, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  PosDealUserPartyView,
  PosOrganizationMembershipStatus,
  PosPublishStatus
} from "@elphi/types";
import { EMPTY, NOT_AVAILABLE } from "../../../constants/common";
import { PublishIcon, UnpublishIcon } from "../../icons";
import {
  SIZE_FIELD,
  TableRowSizeComponent,
  TableTextCell
} from "../../table/TableRowSizeComponent";

export type PosUserRowProps = {
  index: number;
  data: PosDealUserPartyView;
  publishToggleOnClick: (item: PosDealUserPartyView) => void;
};

export const PosUserRow = (props: PosUserRowProps) => {
  const { data, publishToggleOnClick } = props;
  const { publishStatus } = data;
  const isDisabled =
    publishStatus === PosPublishStatus.Publishing ||
    publishStatus === PosPublishStatus.Unpublishing;

  const showPublishIcon =
    !publishStatus ||
    publishStatus === PosPublishStatus.Publishing ||
    publishStatus === PosPublishStatus.Unpublished ||
    publishStatus === PosPublishStatus.FailedToPublished;
  const showUnpublishIcon =
    publishStatus === PosPublishStatus.Unpublishing ||
    publishStatus === PosPublishStatus.Published ||
    publishStatus === PosPublishStatus.FailedToUnpublished;

  const parties = Object.values(data.partyMap || {});
  return (
    <Flex w={"100%"} justify={"space-between"}>
      <TableRowSizeComponent size={SIZE_FIELD.S} withBorderRightWidth={false}>
        <Flex alignItems={"center"} justifyContent={"center"} gap={"18px"}>
          <Tooltip
            label={
              showUnpublishIcon
                ? "Unpublish deal"
                : showUnpublishIcon
                ? "Publish the deal to the POS user to make it viewable in their borrower portal"
                : ""
            }
          >
            <IconButton
              size={"sm"}
              aria-label="publish-toggle"
              icon={
                showPublishIcon ? (
                  <PublishIcon w={6} h={6} ml={"6px"} mt={"6px"} />
                ) : showUnpublishIcon ? (
                  <UnpublishIcon w={6} h={6} ml={"6px"} mt={"6px"} />
                ) : (
                  <></>
                )
              }
              onClick={() => publishToggleOnClick(data)}
              isDisabled={isDisabled}
            />
          </Tooltip>
        </Flex>
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell text={data.userName} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell text={data.userEmail} />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        {parties.length > 1 ? (
          <Box>
            {parties.map((x, i) => (
              <Box
                key={i}
                borderTopWidth={i > 0 ? "2px" : EMPTY}
                borderTopColor={i > 0 ? "grey.100" : EMPTY}
              >
                <Center>{x.name}</Center>
              </Box>
            ))}
          </Box>
        ) : (
          <TableTextCell text={parties.at(0)?.name || NOT_AVAILABLE} />
        )}
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell
          text={orgMembershipStatusMap?.[data?.orgMembershipStatus || EMPTY]}
        />
      </TableRowSizeComponent>
      <TableRowSizeComponent size={SIZE_FIELD.L} withBorderRightWidth={false}>
        <TableTextCell
          text={publishStatusMap?.[data?.publishStatus || EMPTY]}
        />
      </TableRowSizeComponent>
    </Flex>
  );
};

const publishStatusMap = {
  [PosPublishStatus.Publishing]: "Publishing",
  [PosPublishStatus.Published]: "Published",
  [PosPublishStatus.FailedToPublished]: "Failed to Publish",
  [PosPublishStatus.Unpublishing]: "Unpublishing",
  [PosPublishStatus.Unpublished]: "Unpublished",
  [PosPublishStatus.FailedToUnpublished]: "Failed to Unpublish"
} as const;

const orgMembershipStatusMap = {
  [PosOrganizationMembershipStatus.Pending]: "Pending",
  [PosOrganizationMembershipStatus.Accepted]: "Accepted",
  [PosOrganizationMembershipStatus.Invited]: "Invited",
  [PosOrganizationMembershipStatus.Failed]: "Failed"
} as const;
