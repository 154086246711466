import { pick } from "lodash";
import {
  baseAggregationLoanProductType,
  baseLoanPayment,
  baseLoanTermPeriodMonthCount,
  basePaymentDueDay
} from "../../base/deal/baseDeal.enumeration";
import { LoanPurposeType } from "../../deal.enums.types";

export const limaChannelType = {
  InsideSales: "Inside Sales",
  OutsideSales: "Outside Sales"
} as const;

export const limaLenderIdentifierType = {
  LimaOneCapital: "Lima One Capital, LLC",
  InvestorLendingUSA: "Investor Lending USA",
  REIServicing: "The REI Lender, LLC"
} as const;

export const limaLoanProductType = {
  ...pick(baseAggregationLoanProductType, [
    "N51ARM",
    "N101ARM",
    "N5IO25ARM",
    "Fixed"
  ])
} as const;

export const limaLoanPurposeType = {
  ...pick(LoanPurposeType, [
    "Purchase",
    "RateTermRefinance",
    "CashOutRefinance"
  ]),
  PurchaseAndRateTermRefinance: "Purchase and Rate Term Refinance",
  PurchaseAndCashOutRefinance: "Purchase and Cash Out Refinance"
} as const;

export const limaLoanTermPeriodMonthCount = {
  N13: "13",
  N19: "19",
  ...pick(baseLoanTermPeriodMonthCount, ["N24"]),
  N60: "60",
  N120: "120",
  ...pick(baseLoanTermPeriodMonthCount, ["N360"])
} as const;

export const limaPaymentDueDay = {
  ...pick(basePaymentDueDay, ["N10"])
} as const;

export const limaLoanPayment = {
  ...pick(baseLoanPayment, ["PI", "IO"]),
  IOConvertedToPI: "IO Converted To P&I"
} as const;

export const limaWarehouseBank = {
  AmerisBank: "Ameris Bank",
  BancOfCalifornia: "Banc Of California",
  GeorgiaBankingCompany: "Georgia Banking Company",
  LimaOneCapital: "Lima One Capital",
  SilvergateBank: "Silvergate Bank",
  Flagstar: "Flagstar",
  DeutscheBank: "Deutsche Bank",
  JPMorgan: "JP Morgan"
} as const;

export const limaDealOperationsDepartmentType = {
  EastCoastOperations: "East Coast Operations",
  WestCoastOperations: "West Coast Operations",
  StrategicAccounts: "Strategic Accounts",
  LenderFinanceOperations: "Lender Finance Operations"
} as const;

export const limaDealExceptionApprovedBy = {
  Underwriter: "Underwriter",
  Director: "Director",
  CreditReviewCommittee: "Credit Review Committee"
} as const;
