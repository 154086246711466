import { ServiceProviderType } from "@elphi/types";
import { serviceProviderApi } from "../../../../../redux/v2/rolodex";
import { ServiceProvideSearchHooksProps } from "./serviceProviderSearch.types";

export const useSearchApiHooks = (props: ServiceProvideSearchHooksProps) => {
  const { params, type } = props;

  const [searchCompanyApi, searchCompanyApiResponse] =
    serviceProviderApi.useLazySearchCompanyQuery();

  const [searchBranchApi, searchBranchApiResponse] =
    serviceProviderApi.useLazySearchBranchQuery();

  const [searchRepApi, searchRepApiResponse] =
    serviceProviderApi.useLazySearchRepQuery();

  const searchApi = () => {
    if (type === ServiceProviderType.Company) {
      return {
        response: searchCompanyApiResponse,
        fetchData: (r: { query: string; cursor?: string }) => {
          return searchCompanyApi(
            {
              query: r.query,
              cursor: r.cursor,
              ...params
            },
            true
          );
        }
      };
    }
    if (type === ServiceProviderType.Branch) {
      return {
        response: searchBranchApiResponse,
        fetchData: (r: { query: string; cursor?: string }) => {
          return searchBranchApi(
            {
              query: r.query,
              cursor: r.cursor,
              ...params
            },
            true
          );
        }
      };
    }
    if (type === ServiceProviderType.Representative) {
      return {
        response: searchRepApiResponse,
        fetchData: (r: { query: string; cursor?: string }) => {
          return searchRepApi(
            {
              query: r.query,
              cursor: r.cursor,
              ...params
            },
            true
          );
        }
      };
    }
    throw new Error("Search type not support");
  };

  return {
    searchApi
  };
};
