import { aggregationFieldTemplate } from "../../../../types/aggregations";
import { BooleanSelectType } from "../../../../types/entities/fieldFormat.types";
import {
  StateCode,
  StateName
} from "../../../../types/entities/location.types";
import {
  ContactPointRoleType,
  CreditReportPullType,
  EntityParty,
  EntityType,
  FixNFlipNBridgePlusTierType,
  IndividualParty,
  LOCStatusType,
  PartyMilestoneType,
  PartyOperationsDepartmentType,
  PartyType,
  TaxpayerIdentifierType
} from "../../../../types/entities/party.types";
import { DEFAULT_PARTY_GROUP } from "../../../../types/entities/partyGroup.types";

export const getPartyAggregationTemplate = () => {
  const template = {
    TotalStatementQualifyingBalance: aggregationFieldTemplate(),
    BackgroundReportExpirationDate: aggregationFieldTemplate()
  };
  return template;
};
export const getPartyIndividualAggregationTemplate = () => {
  const template = {
    LOCExpirationDate: aggregationFieldTemplate()
  };
  return template;
};

/** @deprecated
 * Use the customer-specific template
 */
export const getPartyTemplate = (t: PartyType | undefined) => {
  if (!t) return {} as IndividualParty;
  const individual: IndividualParty = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    aggregations: {
      ...getPartyAggregationTemplate(),
      ...getPartyIndividualAggregationTemplate()
    },
    TaxpayerIdentifierType: TaxpayerIdentifierType.SSN,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    BirthDate: "",
    GovernmentIssuedIDExpirationDate: "",

    CitizenshipCountry: "",
    CompletedExits: "",
    ContactPointEmailValue: "",
    ContactPointRoleType: "" as ContactPointRoleType,
    ContactPointTelephoneValue: "",
    CreditReportNotes: "",
    CreditReportDate: "",
    CreditReportPullType: "" as CreditReportPullType,
    BackgroundReportDate: "",
    FixNFlipNBridgePlusTier: "" as FixNFlipNBridgePlusTierType,
    LOCStatusType: LOCStatusType.None,
    LoanAmountInProcess: "",

    NewConstructionEligibilityIndicator: "",
    RemainingLOCAvailable: "",
    TotalApprovedLOC: "",
    UnpaidPrincipalBalance: "",
    ExperienceNotes: "",
    FlipsAverageProjectDuration: "",
    FlipsAverageSalePrice: "",
    FlipsCumulativeSalePrice: "",
    NewBuildsAverageProjectDuration: "",
    NewBuildsAverageSalePrice: "",
    NewBuildsCumulativeSalePrice: "",
    NumberOfFlips: "",
    NumberOfNewBuilds: "",
    NumberOfTransactions: "",
    TransactionsAverageProjectDuration: "",
    TransactionsAverageSalePrice: "",
    TransactionsCumulativeSalePrice: "",
    Address: {
      AddressUnitIdentifier: "",
      CityName: "",
      PostalCode: "",
      StateName: "" as StateName,
      AddressLineText: "",
      CountyName: "",
      StateCode: "" as StateCode
    },
    LiquidAssetTotalAmount: "",
    OperationsDepartment: "" as PartyOperationsDepartmentType,
    PartyType: PartyType.Individual,
    TaxpayerIdentifierValue: "",
    USCitizenshipIndicator: "" as BooleanSelectType,
    PaymentTokenId: "",
    CRMId: "",
    EstimatedCreditScore: "",
    ConvictionIndicator: "",
    OutstandingLawsuitsIndicator: "",
    OutstandingJudgmentsIndicator: "",
    BankruptcyIndicator: "",
    ForeclosureIndicator: "",
    MortgageDelinquentIndicator: "",
    StatedCashOnHandAmount: "",
    GroupId: DEFAULT_PARTY_GROUP
  };

  const entity: EntityParty = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    aggregations: getPartyAggregationTemplate(),
    TaxpayerIdentifierType: TaxpayerIdentifierType.EIN,
    BusinessIncorporationStateName: "" as StateName,
    BusinessIncorporationStateCode: "" as StateCode,
    BusinessRegistrationStates: [],
    EntityType: "" as EntityType,
    FixAndFlipBridgePlusTier: "" as FixNFlipNBridgePlusTierType,
    FullName: "",
    LiquidAssetTotalAmount: "",
    OperationsDepartment: "" as PartyOperationsDepartmentType,
    NewConstructionEligibilityIndicator: "",
    Notes: "",
    BackgroundReportDate: "",
    EvidenceOfGoodStandingExpirationDate: "",
    PartyType: PartyType.Entity,
    RealEstateOperationType: [],
    RemainingLineOfCreditAmount: "",
    TaxpayerIdentifierValue: "",
    TotalFixAndFlipNewConstructionSoldPastThreeYearsCount: "",
    Address: {
      AddressUnitIdentifier: "",
      CityName: "",
      PostalCode: "",
      StateName: "" as StateName,
      AddressLineText: "",
      CountyName: "",
      StateCode: "" as StateCode
    },
    PartyMilestone: PartyMilestoneType.NewBorrower,
    RepresentativeCreditScore: "",
    TotalOutstandingLongTermLoanCount: "",
    TotalOutstandingLongTermPrincipalAmount: "",
    CRMId: "",
    ContactPointTelephoneValue: "",
    ContactPointEmailValue: "",
    GroupId: DEFAULT_PARTY_GROUP,
    OverridePartyValidationSettingsIndicator: BooleanSelectType.false
  };

  switch (t) {
    case PartyType.Entity:
      return entity;
    case PartyType.Individual:
      return individual;
  }
};
