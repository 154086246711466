import { Flex } from "@chakra-ui/react";
import { ElphiFieldsTemplateType, RolodexConfiguration } from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../../../../../config/appConfig";
import { auth } from "../../../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../../../hooks/authStateChange.hooks";
import { useFieldsTemplateHooks } from "../../../../../../hooks/fieldsTemplate.hooks";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import useWindowDimensions from "../../../../../../hooks/windowDimensions";
import { ElphiPaginationList } from "../../../../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../../../../form-builder/InputBuilder";
import { TableRowSizeComponent } from "../../../../../table/TableRowSizeComponent";
import { ConfigurationRow } from "./ConfigurationRow";
import { useTableHeaderHooks } from "./tableHeaders.hooks";

const LIMIT = 10;
export const ConfigurationTable = () => {
  const {
    paginationHandler,
    configurationState,
    updateConfigurationBatch,
    selectedConfiguration
  } = useRolodexConfiguration();

  const { fetchFieldsTemplate } = useFieldsTemplateHooks();
  useEffect(() => {
    if (auth.currentUser && configurationState?.ids.length <= LIMIT) {
      paginationHandler.next();
    }
  }, []);

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        paginationHandler.next();
        fetchFieldsTemplate({
          remplateType:
            ElphiFieldsTemplateType.SERVICE_PROVIDERS_MANAGEMENT_PAGE
        });
      }
    },
    deps: [auth.currentUser]
  });

  const { syncState, state, onChange } = useFormBuilderStateHandler<{
    [id: string]: Partial<RolodexConfiguration>;
  }>({
    initialState: {},
    callback: updateConfigurationBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const { headerCells, filteredIds, filters } = useTableHeaderHooks();
  const { heightOffsetInPx } = useWindowDimensions();

  return (
    <Flex width="100%">
      <ElphiPaginationList
        height={heightOffsetInPx(320)}
        tableName={"serviceDomainConfiguration"}
        isLoading={paginationHandler.pageResponse.isLoading}
        header={
          <TableRowSizeComponent
            row={headerCells}
            withBorderRightWidth={false}
            justify="space-evenly"
          />
        }
        pageSize={LIMIT}
        items={
          !selectedConfiguration ? filteredIds : [selectedConfiguration.id]
        }
        next={() => paginationHandler.next(filters)}
        hasMore={paginationHandler.pageData?.ids?.length === LIMIT}
        options={{
          showEndMessage: false,
          showWatching: false
        }}
        rowBuilder={(id, index) => {
          const data = configurationState.entities[id];
          return data ? (
            <ConfigurationRow
              index={Number(index)}
              data={data}
              state={state}
              syncState={syncState}
              onChange={onChange}
            />
          ) : (
            <></>
          );
        }}
      />
    </Flex>
  );
};
