import { merge, omit } from "lodash";
import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import { BaseSchemaDeal } from "../../../../../../types/entities/base";
import {
  AmortizationType,
  ExecutionType,
  FundingShieldStatusType,
  PropertyManagerType,
  RecourseType
} from "../../../../../../types/entities/deal.enums.types";
import { QuoteType } from "../../../../../../types/entities/deal.quote.types";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  LimaDeal,
  LimaDealFields,
  limaChannelType,
  limaDealExceptionApprovedBy,
  limaFloodInsurancePremiumPayment,
  limaLenderIdentifierType,
  limaLoanPayment,
  limaLoanTermPeriodMonthCount,
  limaPaymentDueDay,
  limaWarehouseBank
} from "../../../../../../types/entities/lima";
import { DotNestedKeys } from "../../../../../../types/utils/flatten";
import {
  getFundingShieldWireInsuranceCertAggregationTempate,
  getFundingShieldWireInsuranceCertTempate
} from "../../integrations/property";
import { getBaseSchemaDealTemplate } from "../base-schema";

export const limaDealBaseFieldsToOmit: DotNestedKeys<BaseSchemaDeal>[] = [
  "Channel",
  "LenderIdentifier",
  "LoanTermPeriodMonthCount",
  "PaymentDueDay",
  "LoanPaymentType",
  "FloodInsurancePremiumPaymentType",
  "WarehouseBankType",
  "InterestType",
  "MERSMin",
  "FundingDate",
  "PrincipalBalance",
  "LateFeeAmount",
  "InvestorInterestRate",
  "InterestRateBuydownPercent",
  "OriginationFeePercent",
  "NoteRatePercent",
  "PrepaymentPenaltyType",
  "PrepaymentPenaltyTerm",
  "LoanProductType",
  "FloorPercent",
  "CeilingPercent",
  "MarginPercent",
  "InterestReserveEscrowAmount",
  "ServicerLoanIdentifier",
  "InvestorLoanIdentifier",
  "ServicerAccountIdentifier",
  "SoldDate",
  "ServicingTransferDate",
  "ServicerIdentifier",
  "AssignmentStatus",
  "ExtendedMaturityDate",
  "PaidOffIndicator",
  "DefaultStatus",
  "FinalPrice",
  "InvestorIdentifier",
  "ShortTermRentalIndicator",
  "LockPeriod",
  "aggregations.MonthlyPrincipalAndInterestPaymentAmount",
  "aggregations.TotalInitialFundedAmount",
  "aggregations.MonthlyInterestOnlyPaymentAmount",
  "aggregations.TotalCostBasisAmount",
  "aggregations.FullyDrawnInterestOnlyPayment",
  "aggregations.InitialMonthlyPayment",
  "aggregations.TotalMonthlyEscrowPaymentsAmount",
  "aggregations.InterestRateBuydownAmount",
  "aggregations.OriginationFeesAmount",
  "aggregations.AmortizationStartDate",
  "aggregations.IoPeriodEndDate",
  "aggregations.TotalPitiaAmount",
  "aggregations.TotalLiquidReservesIncludingCashAtClosing",
  "aggregations.MonthsReservesIncludingCashAtClosing",
  "aggregations.MonthsReservesExcludingCashAtClosing",
  "aggregations.TotalPiti",
  "aggregations.TotalMonthlyHoaFeesAmount",
  "aggregations.TotalAsIsLtvPercent",
  "aggregations.TotalLttcRatePercent",
  "aggregations.TotalLtcRatePercent",
  "aggregations.TotalDscr",
  "aggregations.TotalMonthlyPaymentOfTaxesAndInsurance"
];

const getDealQuoteTemplate = () => {
  const thirdPartyField = thirdPartyFieldTemplate();
  const calculatedField = aggregationFieldTemplate();
  const template: LimaDeal["quote"] = {
    quoteType: "" as QuoteType,
    aggregations: {
      // SHARED GET

      OriginationFeeAmount: thirdPartyField,
      ProcessingFeeAmount: thirdPartyField,
      BrokerOriginationFeeAmount: thirdPartyField,
      ServicingSetupFee: thirdPartyField,
      LenderFinanceProcessingFee: thirdPartyField,
      LenderFinanceApplicationFee: thirdPartyField,
      LimaRateToBorrowerPercent: thirdPartyField,
      LimaOriginationFeeToBorrowerPercent: thirdPartyField,
      PricingEngineWarnings: thirdPartyField,
      BrokerProcessingFeeAmount: thirdPartyField,
      FeeLLPAs: thirdPartyField,
      RateLLPAs: thirdPartyField,
      PricingEngineOverrides: thirdPartyField,
      LenderFinanceDocumentFee: thirdPartyField,
      LenderFinanceAppraisalFee: thirdPartyField,
      ValidationErrors: thirdPartyField,
      QuoteId: thirdPartyField,
      AchRequiredIndicator: thirdPartyField,
      AchExemptionStatus: thirdPartyField,
      PricingInfo: thirdPartyField,
      CampaignCode: thirdPartyField,
      CampaignRateIncentive: thirdPartyField,
      CampaignFeeIncentive: thirdPartyField,
      CampaignLeverageIncentive: thirdPartyField,
      CampaignExpirationDate: thirdPartyField,
      // RENTAL GET
      LtvEligibility: thirdPartyField,
      InterestReserveEscrow: thirdPartyField,
      PrepaymentPenaltyType: thirdPartyField,
      NoteRatePercent: thirdPartyField,
      MonthlyPrincipalInterestPaymentAmount: thirdPartyField,
      MonthlyTaxInsurancePaymentAmount: thirdPartyField,
      MonthlyHomeownersAssociationAmount: thirdPartyField,
      TotalMonthlyPITIPaymentAmount: thirdPartyField,
      DebtServiceCoverageRatioPercent: thirdPartyField,
      BuyDownFeeAmount: thirdPartyField,
      BuyUpFeeReductionAmount: thirdPartyField,
      Margin: thirdPartyField,
      FloorPercent: thirdPartyField,
      LifetimeCapPercent: thirdPartyField,
      DeferredOriginationFeeAmount: thirdPartyField,

      // RENTAL POST
      scenario: {
        scenario: [
          {
            scenarioId: thirdPartyField
          }
        ]
      },
      UnderPropertyValuationAmount: calculatedField,
      UnderPropertyValuationTotalCost: calculatedField,
      PrimaryBorrowerCreditScore: calculatedField,
      StateNY: calculatedField,
      InternalL1CRefinanceIndicator: calculatedField,
      BorrowingEntity: calculatedField,
      OverPropertyValuationAmount: calculatedField,
      TotalValuationOrPurchasePriceAmount: calculatedField,
      PropertyTypeCondoIndicator: calculatedField,
      PropertyValuationAmountUnderIndicator: calculatedField,
      USCitizenshipIndicator: calculatedField,
      SeasonalRental: calculatedField,
      PropertyTypeMultifamilyIndicator: calculatedField,
      // SHORT-TERM GET
      InitialMonthlyInterestOnlyPaymentAmount: thirdPartyField,
      PricingEngineLTARVRatePercent: thirdPartyField,
      PricingEngineTotalLoanAmount: thirdPartyField,
      FullyDrawnInterestOnlyPayment: thirdPartyField,
      PricingEngineTotalLoanToAfterRepairValueOverridePercent: thirdPartyField,
      PricingEngineTotalBlendedLoanToCostOverridePercent: thirdPartyField,
      PricingEngineTotalPurchaseLoanToCostOverridePercent: thirdPartyField,
      PricingEngineNoteRateOverridePercent: thirdPartyField,

      PricingEngineMaxLoanToAfterRepairValuePercent: thirdPartyField,
      PricingEngineMaxBlendedLoanToCostPercent: thirdPartyField,
      PricingEngineMaxPurchaseLoanToCostPercent: thirdPartyField,
      BlendedLTCAdjuster: thirdPartyField,
      PurchaseLTCAdjuster: thirdPartyField,
      ARVAdjuster: thirdPartyField,

      // SHORT-TERM POST
      PrimaryGuarantor: calculatedField,
      CompletedExits: calculatedField,
      SumTotalStatementQualifyingBalance: calculatedField,
      AltTier: calculatedField,
      LesserOfLotOrPurchase: calculatedField,
      DebtStatus: calculatedField,
      PropertyAddressLineText: calculatedField,
      PropertyCityName: calculatedField,
      PropertyStateCode: calculatedField,
      PropertyPostalCode: calculatedField,
      PropertyType: calculatedField,
      PropertyId: calculatedField,
      OriginalPurchaseDate: calculatedField,
      BorrowerTier: calculatedField
    }
  };
  return template;
};

export const getLimaSchemaDealTemplate = (): LimaDealFields => {
  const baseDeal = omit(getBaseSchemaDealTemplate(), limaDealBaseFieldsToOmit);
  const calculatedField = aggregationFieldTemplate();
  const limaDealFields: LimaDealFields = {
    AllongeAssignmentSentToWarehouseIndicator: "" as BooleanSelectType,
    AllongeAssignmentTrackingNumber: "",
    AmortizationType: "" as AmortizationType,
    ApplicationRequestedLoanAmount: "",
    AppraisalNotes: "",
    AssociatedPropertiesDescription: "",
    Broker: {
      BrokerWiringInstructionsIndicator: "" as BooleanSelectType,
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullName: "",
      NMLSIdentifier: "",
      RepresentativeFirstName: "",
      RepresentativeLastName: ""
    },
    BuildingEnvelopeExpandedIndicator: "" as BooleanSelectType,
    BuildingPermitsExpectedDate: "",
    BuildingPermitsIndicator: "" as BooleanSelectType,
    Channel: "" as OptionsOf<typeof limaChannelType>,
    ChargentCollectionAmount: "",
    ChargentGatewayIdentifier: "",
    ChargentTransactionIdentifier: "",
    ConstructionBudgetRemainingAmount: "",
    ConstructionBudgetVerifiedMatchIndicator: "" as BooleanSelectType,
    CreditReviewCommittee: {
      creditMemoApprovedDate: "",
      creditMemoDraftedDate: "",
      externalApprovalDate: "",
      externalSubmissionDate: "",
      internalApprovalDate: "",
      internalSubmissionDate: "",
      lOIAcceptedDate: "",
      lOIIssuedDate: ""
    },
    CRMId: "",
    CurrentLotMarketValueAmount: "",
    DeferredMaintenanceAmount: "",
    DevelopmentStrategy: "",
    EmployeeLoanIndicator: "" as BooleanSelectType,
    EONamedInsuredMatchIndicator: "" as BooleanSelectType,
    Exceptions: {
      ExceptionApprovedBy: "" as OptionsOf<typeof limaDealExceptionApprovedBy>,
      PolicySection: []
    },
    ExecutionType: "" as ExecutionType,
    ExitStrategy: "",
    FloodInsuranceAgency: {
      FullName: ""
    },
    FloodInsurancePremiumPaymentType: "" as OptionsOf<
      typeof limaFloodInsurancePremiumPayment
    >,
    FundingAndDisbursementApprovalIndicator: "" as BooleanSelectType,
    FundingShieldStatusType: "" as FundingShieldStatusType,
    GeneralContractor: {
      CapitalExpenditureAmount: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullName: "",
      RepresentativeFirstName: "",
      RepresentativeLastName: ""
    },
    GrossSpreadPercent: "",
    HOAContactEmail: "",
    HOAContactName: "",
    HOAContactPhone: "",
    InitialLTVRatePercent: "",
    Integrations: {
      WireInsuranceCertificate: {
        FundingShield: getFundingShieldWireInsuranceCertTempate()
      }
    },
    InterestReserveEscrowOverrideAmount: "",
    InterestReserveEscrowOverrideMonthCount: "",
    LastPaymentDueDate: "",
    LenderFinance: {
      BorrowerFinalTermSheetApprovalStatusDate: "",
      PartnerFinalTermSheetApprovalStatusDate: "",
      PartnerFirstName: "",
      PartnerInitialTermSheetApprovalStatusDate: "",
      PartnerInitialTermSheetApprovalStatusIndicator: "" as BooleanSelectType,
      PartnerLastName: ""
    },
    LenderIdentifier: "" as OptionsOf<typeof limaLenderIdentifierType>,
    LenderPropertyInsuranceProviderIndicator: "" as BooleanSelectType,
    LenderTitleProviderIndicator: "" as BooleanSelectType,
    LetterOfIntentSignedDate: "",
    LienDollarAmount: "",
    LoadBearingWallsRemoveIntendedIndicator: "" as BooleanSelectType,
    LoanAfterInterestOnlyPeriodMonthCount: "",
    LoanAmountVerifiedMatchIndicator: "" as BooleanSelectType,
    LoanPackageCheckAmount: "",
    LoanPackageCheckNumber: "",
    LoanPackageChecksReceivedIndicator: "" as BooleanSelectType,
    LoanPaymentType: "" as OptionsOf<typeof limaLoanPayment>,
    LoanTermPeriodMonthCount: "" as OptionsOf<
      typeof limaLoanTermPeriodMonthCount
    >,
    LotStatus: "",
    LotUtilitiesIndicator: "",
    LotZonedParcelIdIndicator: "",
    Occupancy: "",
    OccupationIndicator: "",
    PaymentDueDay: "" as OptionsOf<typeof limaPaymentDueDay>,
    PersonalGuarantorsCount: "",
    PostClosingNotes: "",
    PowerOfAttorneyFullName: "",
    ProcessingFeeOverride: "",
    ProgramDirectorApprovalIndicator: "" as BooleanSelectType,
    ProgramDirectorComments: "",
    ProjectAnticipatedCompletionTimeline: "",
    PromissoryNoteRequestedIndicator: "" as BooleanSelectType,
    PromissoryNoteShippedIndicator: "" as BooleanSelectType,
    PromissoryNoteTrackingNumber: "",
    PropertyInsuranceAgency: {
      FullName: ""
    },
    PropertyLeasePurchaseOptionIndicator: "",
    PropertyManagement: {
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullAddressName: "",
      FullName: "",
      PropertyManagementComments: "",
      PropertyManagerType: "" as PropertyManagerType,
      RepresentativeFullName: ""
    },
    PropertyRentReadyConditionIndicator: "",
    PropertyTypeChangingIndicator: "" as BooleanSelectType,
    PropertyZoningChangesIndicator: "" as BooleanSelectType,
    quote: getDealQuoteTemplate(),
    RecourseType: "" as RecourseType,
    RecordedMortgageReceivedDate: "",
    RecordedWarrantyDeedReceivedDate: "",
    ReleasePercent: "",
    SellerRelationshipIndicator: "",
    SettlementStatementMatchLedgerIndicator: "" as BooleanSelectType,
    SquareFootageAddedIndicator: "" as BooleanSelectType,
    SubdividePropertyPartialReleasesIndicator: "",
    TargetCloseDate: "",
    TitleCommitment: {
      AllPayoffsLiensIncludedInSection1RequirementsIndicator:
        "" as BooleanSelectType,
      LeaseholdEndorsementIndicator: "" as BooleanSelectType,
      RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator:
        "" as BooleanSelectType
    },
    TitlePolicyReceivedDate: "",
    ValuationFeesCollectedBySalesIndicator: "" as BooleanSelectType,
    VerifiableConstructionCompletedAmount: "",
    WarehouseBankType: "" as OptionsOf<typeof limaWarehouseBank>,
    WireInsuranceMatchDealIndicator: "",
    aggregations: {
      ARMRepriceFrequencyType: calculatedField,
      Integrations: {
        WireInsuranceCertificate: {
          FundingShield: getFundingShieldWireInsuranceCertAggregationTempate()
        }
      },
      MonthlyPaymentOfCapitalExpenditure: calculatedField,
      TotalAnnualCapitalExpenditure: calculatedField,
      TotalBrokerFeeAmount: calculatedField
    }
  };
  return merge(baseDeal, limaDealFields);
};
