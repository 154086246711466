import {
  PartyType,
  SystemSetup,
  getBaseSchemaPartyPartyTemplate,
  getLimaPartyTemplate,
  getMofinPartyTemplate
} from "../../../../types";
import { SystemSetupTemplates } from "../types";

export const partyTemplates: Record<PartyType, SystemSetupTemplates> = {
  [PartyType.Entity]: {
    [SystemSetup.Elphi]: () =>
      getBaseSchemaPartyPartyTemplate(PartyType.Entity),
    [SystemSetup.Mofin]: () => getMofinPartyTemplate(PartyType.Entity),
    [SystemSetup.Lima]: () => getLimaPartyTemplate(PartyType.Entity)
  },
  [PartyType.Individual]: {
    [SystemSetup.Elphi]: () =>
      getBaseSchemaPartyPartyTemplate(PartyType.Individual),
    [SystemSetup.Mofin]: () => getMofinPartyTemplate(PartyType.Individual),
    [SystemSetup.Lima]: () => getLimaPartyTemplate(PartyType.Individual)
  }
};
