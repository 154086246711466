import { aggregationFieldTemplate } from "../../../../../../types/aggregations";
import {
  BaseSchemaEntityParty,
  BaseSchemaIndividualParty,
  baseCitizenshipResidency,
  baseFixNFlipNBridgePlusTier,
  baseLOCStatus,
  basePartyMilestone
} from "../../../../../../types/entities/base";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  StateCode,
  StateName
} from "../../../../../../types/entities/location.types";
import {
  ContactPointRoleType,
  CreditReportPullType,
  EntityType,
  PartyOperationsDepartmentType,
  PartyType,
  TaxpayerIdentifierType
} from "../../../../../../types/entities/party.types";
import { DEFAULT_PARTY_GROUP } from "../../../../../../types/entities/partyGroup.types";
import { BaseFields } from "../types";

const getBaseSchemaEntityPartyTemplate = (): Omit<
  BaseSchemaEntityParty,
  BaseFields
> => {
  const calculatedFieldTelpate = aggregationFieldTemplate();

  return {
    Address: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      CountyName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    aggregations: {
      BackgroundReportExpirationDate: calculatedFieldTelpate,
      TotalStatementQualifyingBalance: calculatedFieldTelpate
    },
    AuthorizedContacts: "",
    BackgroundReportDate: "",
    BusinessIncorporationStateCode: "" as StateCode,
    BusinessIncorporationStateName: "" as StateName,
    BusinessRegistrationStates: [],
    CitizenshipResidency: "" as OptionsOf<typeof baseCitizenshipResidency>,
    ContactPointEmailValue: "",
    ContactPointTelephoneValue: "",
    CreditReportDate: "",
    CreditScore: "",
    EntityType: "" as EntityType,
    EvidenceOfGoodStandingExpirationDate: "",
    FixAndFlipBridgePlusTier: "" as OptionsOf<
      typeof baseFixNFlipNBridgePlusTier
    >,
    FullName: "",
    GroupId: DEFAULT_PARTY_GROUP,
    LiquidAssetTotalAmount: "",
    NewConstructionEligibilityIndicator: "",
    Notes: "",
    NumberOfRehabProjects: "",
    NumberOfTransactions: "",
    OperationsDepartment: "" as PartyOperationsDepartmentType,
    PartyMilestone: "" as OptionsOf<typeof basePartyMilestone>,
    PartyType: PartyType.Entity,
    RealEstateOperationType: [],
    RemainingLineOfCreditAmount: "",
    RepresentativeCreditScore: "",
    TaxpayerIdentifierType: TaxpayerIdentifierType.EIN,
    TaxpayerIdentifierValue: "",
    TotalFixAndFlipNewConstructionSoldPastThreeYearsCount: "",
    TotalOutstandingLongTermLoanCount: "",
    TotalOutstandingLongTermPrincipalAmount: ""
  };
};

const getBaseSchemaPartyIndividualTemplate = (): Omit<
  BaseSchemaIndividualParty,
  BaseFields
> => {
  const calculatedFieldTelpate = aggregationFieldTemplate();

  return {
    Address: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      CountyName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    BankruptcyIndicator: "" as BooleanSelectType,
    BirthDate: "",
    CitizenshipCountry: "",
    CitizenshipResidency: "" as OptionsOf<typeof baseCitizenshipResidency>,
    CompletedExits: "",
    ContactPointEmailValue: "",
    ContactPointRoleType: "" as ContactPointRoleType,
    ContactPointTelephoneValue: "",
    ConvictionIndicator: "" as BooleanSelectType,
    CreditReportDate: "",
    CreditReportNotes: "",
    CreditReportPullType: "" as CreditReportPullType,
    CreditScore: "",
    EstimatedCreditScore: "",
    ExperienceNotes: "",
    FirstName: "",
    FixNFlipNBridgePlusTier: "" as OptionsOf<
      typeof baseFixNFlipNBridgePlusTier
    >,
    FlipsAverageProjectDuration: "",
    FlipsAverageSalePrice: "",
    FlipsCumulativeSalePrice: "",
    ForeclosureIndicator: "" as BooleanSelectType,
    GovernmentIssuedIDExpirationDate: "",
    GroupId: DEFAULT_PARTY_GROUP,
    LastName: "",
    LiquidAssetTotalAmount: "",
    LOCStatusType: baseLOCStatus.None,
    LoanAmountInProcess: "",
    MiddleName: "",
    MortgageDelinquentIndicator: "" as BooleanSelectType,
    NewBuildsAverageProjectDuration: "",
    NewBuildsAverageSalePrice: "",
    NewBuildsCumulativeSalePrice: "",
    NewConstructionEligibilityIndicator: "",
    NumberOfFlips: "",
    NumberOfNewBuilds: "",
    NumberOfRehabProjects: "",
    NumberOfTransactions: "",
    OperationsDepartment: "" as PartyOperationsDepartmentType,
    OutstandingJudgmentsIndicator: "" as BooleanSelectType,
    OutstandingLawsuitsIndicator: "" as BooleanSelectType,
    PartyType: PartyType.Individual,
    RemainingLOCAvailable: "",
    StatedCashOnHandAmount: "" as BooleanSelectType,
    TaxpayerIdentifierType: TaxpayerIdentifierType.SSN,
    TaxpayerIdentifierValue: "",
    TotalApprovedLOC: "",
    TransactionsAverageProjectDuration: "",
    TransactionsAverageSalePrice: "",
    TransactionsCumulativeSalePrice: "",
    UnpaidPrincipalBalance: "",
    USCitizenshipIndicator: "" as BooleanSelectType,
    aggregations: {
      BackgroundReportExpirationDate: calculatedFieldTelpate,
      TotalStatementQualifyingBalance: calculatedFieldTelpate
    },
    BackgroundReportDate: ""
  };
};

export const getBaseSchemaPartyPartyTemplate = (partyType: PartyType) => {
  switch (partyType) {
    case PartyType.Entity:
      return getBaseSchemaEntityPartyTemplate();
    case PartyType.Individual:
      return getBaseSchemaPartyIndividualTemplate();
    default:
      return undefined;
  }
};
