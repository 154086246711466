import {
  SystemSetup,
  getBaseSchemaDealTemplate,
  getLimaSchemaDealTemplate,
  getMofinSchemaDealTemplate
} from "../../../../types";
import { SystemSetupTemplates } from "../types";

export const dealTemplates: SystemSetupTemplates = {
  [SystemSetup.Mofin]: getMofinSchemaDealTemplate,
  [SystemSetup.Elphi]: getBaseSchemaDealTemplate,
  [SystemSetup.Lima]: getLimaSchemaDealTemplate
};
