import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import {
  BaseSchemaDeal,
  baseAssignmentStatus,
  baseChannelType,
  baseDefaultStatus,
  baseFloodInsurancePremiumPayment,
  baseInterestType,
  baseLoanPayment,
  baseLoanProductType,
  baseLoanTermPeriodMonthCount,
  baseLockPeriod,
  basePaymentDueDay,
  basePrepaymentPenaltyTerm,
  basePrepaymentPenaltyType
} from "../../../../../../types/entities/base";
import {
  ARMRepriceLookbackType,
  ARMRepriceRoundingMethodType,
  ARMRepriceRoundingRatePercentType,
  CapitalStructureType,
  CollateralPackageStatusType,
  DealMilestoneType,
  IndexSourceType,
  InterestAccrualMethodType,
  LenderEntityType,
  LoanPurposeType,
  PricingEngineExceptionStatusType,
  PropertyModelType,
  RealEstateProgramType,
  ShippingCompanyType,
  TitleVestingType,
  UnderwriterApprovalStatusType,
  WarehouseBankStatusType
} from "../../../../../../types/entities/deal.enums.types";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  StateCode,
  StateName
} from "../../../../../../types/entities/location.types";
import {
  PropertyInsurancePremiumPaymentType,
  PropertyRightsOwnershipType
} from "../../../../../../types/entities/property.enums.types";
import { BaseFields } from "../types";

export const getBaseSchemaDealTemplate = (): Omit<
  BaseSchemaDeal,
  BaseFields
> => {
  const calculatedFieldTelpate = aggregationFieldTemplate();
  return {
    ACHElectedIndicator: "" as BooleanSelectType,
    AggregateOccupancyPercent: "",
    AllongeOrAssignmentSentToWarehouseIndicator: "" as BooleanSelectType,
    ApplicationReceivedDate: "",
    ARMRepriceLookbackType: "" as ARMRepriceLookbackType,
    ARMRepriceRoundingMethodType: "" as ARMRepriceRoundingMethodType,
    ARMRepriceRoundingRatePercentType: "" as ARMRepriceRoundingRatePercentType,
    AssetSummaryReportIndicator: "" as BooleanSelectType,
    AssignmentStatus: "" as OptionsOf<typeof baseAssignmentStatus>,
    BlendedLTCLoanAmount: "",
    CapitalStructureType: "" as CapitalStructureType,
    CashFromBorrowerAmount: "",
    CashOutAmount: "",
    CashToBorrowerAmount: "",
    CeilingPercent: "",
    Channel: "" as OptionsOf<typeof baseChannelType>,
    ClearToCloseDate: "",
    ClosingAnalystUserId: "",
    CollateralPackageStatusType: "" as CollateralPackageStatusType,
    CollateralPackageTrackingNumber: "",
    ConstructionLTCRatePercent: "",
    CreditMemorandumIndicator: "" as BooleanSelectType,
    PayoffPerDiemInterest: "" as string,
    CreditReviewCommitteeApprovalIndicator: "" as BooleanSelectType,
    DealMetadata: {
      activeUsers: [],
      branches: [],
      milestone: "" as DealMilestoneType,
      milestoneData: {},
      organizations: [],
      teams: []
    },
    DefaultInterestRatePercent: "",
    DefaultStatus: "" as OptionsOf<typeof baseDefaultStatus>,
    DownPaymentAmount: "",
    EOExpirationDate: "",
    Exceptions: {
      ExceptionApprovedBy: "",
      ExceptionCommentary: "",
      ExceptionType: []
    },
    ExtendedMaturityDate: "",
    FinalPrice: "",
    FinalUnderwritingDecision: "",
    FloodInsurancePremiumPaymentType: "" as OptionsOf<
      typeof baseFloodInsurancePremiumPayment
    >,
    FloorPercent: "",
    FloorRatePercent: "",
    FundingDate: "",
    fundingAgentEmailAddress: "",
    GracePeriodDays: "",
    HaircutAmount: "",
    IndexSourceType: "" as IndexSourceType,
    InitialAdvancedFundAmount: "",
    InitialTaskGenerationDate: "",
    IntegrationMetadata: {
      customerPortalId: "",
      salesforceAccountId: "",
      salesforceDealId: ""
    },
    InterestAccrualMethodType: "" as InterestAccrualMethodType,
    InterestRateBuydownPercent: "",
    InterestReserveDepositAmount: "",
    InterestReserveEscrowAmount: "",
    InterestType: "" as OptionsOf<typeof baseInterestType>,
    InvestorIdentifier: "",
    InvestorInterestRate: "",
    InvestorLoanIdentifier: "",
    LateFeeAmount: "",
    LateFeePercent: "",
    Ledger: {
      AppraisalDepositAmount: "",
      AppraisalFeeAmount: "",
      CondoCertificationFeeAmount: "",
      ContractorReviewFeeAmount: "",
      CreditReportFeeAmount: "",
      FeasibilityReviewFeeAmount: "",
      InterestReserveAmount: "",
      LenderCounselFeeAmount: "",
      NewYorkProcessingFeeAmount: ""
    },
    Lender: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      CountyName: "",
      EntityType: "" as LenderEntityType,
      FormationState: "" as StateName,
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    LenderIdentifier: "",
    LienPosition: "",
    LifetimeCapRatePercent: "",
    LoanAmortizationPeriodMonthCount: "",
    LoanAmortizationPeriodYearCount: "",
    LoanFundingDate: "",
    LoanIdentifier: "",
    LoanInterestOnlyPeriodMonthCount: "",
    LoanName: "",
    LoanPackageInternationalExecutionIndicator: "" as BooleanSelectType,
    LoanPackageNoteReceivedByLenderIndicator: "" as BooleanSelectType,
    LoanPackageNoteTrackingNumber: "",
    LoanPackageTrackingNumber: "",
    LoanPaymentType: "" as OptionsOf<typeof baseLoanPayment>,
    LoanProductType: "" as OptionsOf<typeof baseLoanProductType>,
    LoanPurposeType: "" as LoanPurposeType,
    LoanTermPeriodMonthCount: "" as OptionsOf<
      typeof baseLoanTermPeriodMonthCount
    >,
    LOCAvailableAmount: "",
    LOCExpirationDate: "",
    LOCIssuanceDate: "",
    LockPeriod: "" as OptionsOf<typeof baseLockPeriod>,
    MERSMin: "",
    MarginPercent: "",
    MarginRatePercent: "",
    MarketingPromotionCode: "",
    MarketingPromotionCreditAmount: "",
    MaxARVAmount: "",
    mailAwayIndicator: "" as BooleanSelectType,
    NextPaymentDueDate: "",
    NotaryExpirationDate: "",
    NotaryUserId: "",
    NoteRatePercent: "",
    NoteToWarehouseTrackingNumber: "",
    OperationsDepartment: "",
    OriginationFeePercent: "",
    PaidOffIndicator: "" as BooleanSelectType,
    PaymentDueDay: "" as OptionsOf<typeof basePaymentDueDay>,
    PolicySectionNumber: "",
    PortfolioLoanIndicator: "" as BooleanSelectType,
    PreliminaryUnderwritingDecisionDate: "",
    PrepaymentPenaltyTerm: "" as OptionsOf<typeof basePrepaymentPenaltyTerm>,
    PrepaymentPenaltyType: "" as OptionsOf<typeof basePrepaymentPenaltyType>,
    PricingEngineExceptionStatus: "" as PricingEngineExceptionStatusType,
    PrimaryBorrowers: [],
    PrimarySponsors: [],
    PrincipalBalance: "",
    PromissoryNoteComments: "",
    PropertyInsurancePremiumPaymentType:
      "" as PropertyInsurancePremiumPaymentType,
    PropertyModelType: "" as PropertyModelType,
    PurchaserWarrantyDeedAddedIndicator: "" as BooleanSelectType,
    quote: {
      aggregations: {
        BorrowingEntity: calculatedFieldTelpate,
        CompletedExits: calculatedFieldTelpate,
        LesserOfLotOrPurchase: calculatedFieldTelpate,
        PrimaryGuarantor: calculatedFieldTelpate,
        PropertyAddressLineText: calculatedFieldTelpate,
        PropertyCityName: calculatedFieldTelpate,
        PropertyId: calculatedFieldTelpate,
        PropertyPostalCode: calculatedFieldTelpate,
        PropertyStateCode: calculatedFieldTelpate,
        PropertyType: calculatedFieldTelpate,
        TotalValuationOrPurchasePriceAmount: calculatedFieldTelpate
      }
    },
    RateLockEndDate: "",
    RateLockStartDate: "",
    RealEstateProgramType: "" as RealEstateProgramType,
    SecondaryBorrowers: [],
    SecondarySponsors: [],
    SellerHUDAddedIndicator: "" as BooleanSelectType,
    ServicerAccountIdentifier: "",
    ServicerIdentifier: "",
    ServicerLoanIdentifier: "",
    ServicingTransferDate: "",
    SettlementStatementComments: "",
    SettlementStatementFileNumber: "",
    ShippingCompanyType: "" as ShippingCompanyType,
    ShortTermRentalIndicator: "" as BooleanSelectType,
    SoldDate: "",
    SubmissionNotes: "",
    TitleCommitment: {
      AllSubjectPropertiesIncludedIndicator: "" as BooleanSelectType,
      PropertyRightsOwnershipType: "" as PropertyRightsOwnershipType,
      TitleCommitmentComments: "",
      TitleEffectiveDate: "",
      TitleExpirationDate: "",
      TitleInternationalExecutionIndicator: "" as BooleanSelectType,
      TitlePolicyAmount: "",
      TitleVestingType: "" as TitleVestingType
    },
    TitleCompany: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      Attorney: {
        FullName: ""
      },
      CityName: "",
      ClosingAgentContactPointEmailValue: "",
      ClosingAgentContactPointTelephoneValue: "",
      ClosingAgentFirstName: "",
      ClosingAgentLastName: "",
      CountyName: "",
      FullName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName,
      TitleInsuranceUnderwriter: ""
    },
    TitleFormsComments: "",
    TotalCashToFromBorrower: "",
    TotalLiquidAssetAmount: "",
    TotalLoanFeesAndClosingCostAmount: "",
    TotalPropertyCount: "",
    TotalPropertyValuationAmount: "",
    UCCExpirationDate: "",
    UCCFiledIndicator: "" as BooleanSelectType,
    UnderwriterApprovalComments: "",
    UnderwriterApprovalStatus: "" as UnderwriterApprovalStatusType,
    WarehouseBankStatusType: "" as WarehouseBankStatusType,
    WarehouseBankType: "",
    WireABARoutingNumber: "",
    WireAccountName: "",
    WireAccountNumber: "",
    WireBankName: "",
    WireReleaseDate: "",
    aggregations: {
      ARMFinalAdjustableRateTermMonthCount: calculatedFieldTelpate,
      ARMInitialFixedTermMonthCount: calculatedFieldTelpate,
      EstimatedClosingDate: thirdPartyFieldTemplate({
        defaultFocus: "override"
      }),
      FirstRateChangeDate: calculatedFieldTelpate,
      LastDayOfClosingMonth: calculatedFieldTelpate,
      LastPaymentDueDate: calculatedFieldTelpate,
      LoanMaturityDate: calculatedFieldTelpate,
      LoanProductType: thirdPartyFieldTemplate(),
      LoanProgramType: thirdPartyFieldTemplate({ defaultFocus: "override" }),
      NetWireAmount: calculatedFieldTelpate,
      PerDiemPrepaidInterestAmount: calculatedFieldTelpate,
      PrepaidInterestAmount: calculatedFieldTelpate,
      PrepaymentPenaltyExpirationDate: calculatedFieldTelpate,
      RequestedLoanAmount: calculatedFieldTelpate,
      ScheduledFirstPaymentDate: calculatedFieldTelpate,
      TotalAdjustedMonthlyRentAmount: calculatedFieldTelpate,
      TotalAsIsAppraisedValueAmount: calculatedFieldTelpate,
      TotalAssignmentFeeAmount: calculatedFieldTelpate,
      TotalBudgetAmount: calculatedFieldTelpate,
      TotalCostAmount: calculatedFieldTelpate,
      TotalFloodInsurancePremiumAmount: calculatedFieldTelpate,
      TotalFloodInsurancePremiumOutstandingAmount: calculatedFieldTelpate,
      TotalHOAFeesAmount: calculatedFieldTelpate,
      TotalInitialInsuranceEscrowAmount: calculatedFieldTelpate,
      TotalInitialTaxEscrowAmount: calculatedFieldTelpate,
      TotalLTVRatePercent: calculatedFieldTelpate,
      TotalLTARVRatePercent: calculatedFieldTelpate,
      TotalMonthlyMarketRent: calculatedFieldTelpate,
      TotalNumberOfProperties: calculatedFieldTelpate,
      TotalOutstandingLoanPayoffAmount: calculatedFieldTelpate,
      TotalPropertyInsurancePremiumAmount: calculatedFieldTelpate,
      TotalPropertyInsurancePremiumOutstandingAmount: calculatedFieldTelpate,
      TotalPurchasePriceAmount: calculatedFieldTelpate,
      TotalSubjectToAppraisedValueAmount: calculatedFieldTelpate,
      TotalTaxAmount: calculatedFieldTelpate,
      MonthlyPrincipalAndInterestPaymentAmount: calculatedFieldTelpate,
      TotalInitialFundedAmount: calculatedFieldTelpate,
      MonthlyInterestOnlyPaymentAmount: calculatedFieldTelpate,
      TotalCostBasisAmount: calculatedFieldTelpate,
      FullyDrawnInterestOnlyPayment: calculatedFieldTelpate,
      InitialMonthlyPayment: calculatedFieldTelpate,
      AmortizationStartDate: calculatedFieldTelpate,
      InterestRateBuydownAmount: calculatedFieldTelpate,
      IoPeriodEndDate: calculatedFieldTelpate,
      MonthsReservesExcludingCashAtClosing: calculatedFieldTelpate,
      MonthsReservesIncludingCashAtClosing: calculatedFieldTelpate,
      OriginationFeesAmount: calculatedFieldTelpate,
      TotalAsIsLtvPercent: calculatedFieldTelpate,
      TotalDscr: calculatedFieldTelpate,
      TotalLiquidReservesIncludingCashAtClosing: calculatedFieldTelpate,
      TotalLtcRatePercent: calculatedFieldTelpate,
      TotalLttcRatePercent: calculatedFieldTelpate,
      TotalMonthlyEscrowPaymentsAmount: calculatedFieldTelpate,
      TotalMonthlyHoaFeesAmount: calculatedFieldTelpate,
      TotalMonthlyPaymentOfTaxesAndInsurance: calculatedFieldTelpate,
      TotalPiti: calculatedFieldTelpate,
      TotalPitiaAmount: calculatedFieldTelpate
    },
    assignedUsers: []
  };
};
