import { BaseEntity } from "../../db";
import { Task } from "../task.types";

export enum PosPublishEntityStatus {
  Pending = "pending",
  Acknowledged = "acknowledged",
  Skipped = "skipped",
  Failed = "failed"
}

export type POSPublishedEntity = BaseEntity<{
  deal: POSPublishedDealEntity;
  tasks: POSPublishedTaskEntity[];
  status: PosPublishEntityStatus;
}>;

export type POSPublishedDealEntity = {
  [key: string]: unknown;
  id: string;
  correlationId: string;
};

export type POSPublishedTaskEntity = Partial<
  Pick<
    Task,
    | "id"
    | "entityId"
    | "entityType"
    | "configurationId"
    | "fieldMeta"
    | "documents"
    | "pendingReviewOf"
  > & {
    status: string;
    configurationName: string;
    entityInfo: string;
    correlationId: string;
  }
>;
