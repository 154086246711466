import { useDisclosure } from "@chakra-ui/react";
import { PosDealUserPartyView, PosPublishStatus } from "@elphi/types";
import { useState } from "react";
import { useAuthStateChangeHook } from "../../../hooks/authStateChange.hooks";
import { usePosDealUserPartyHooks } from "../../../hooks/posDealUserParty.hooks";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { baseCellStyle } from "../../custom/chakra/Cell";
import { ElphiPaginationList } from "../../elphi-list/ElphiList";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../table/TableRowSizeComponent";
import { ElphiCellType, ElphiTableProps } from "../../table/table.types";
import { PublishModal } from "../modal/PublishModal";
import { UnpublishModal } from "../modal/UnpublishModal";
import { PosUserRow } from "./PosUserRow";

export const PosUserTable = (props: {
  dealId: string;
  loanIdentifier: string;
}) => {
  const { heightOffsetInPx } = useWindowDimensions();
  const [selectedRow, setSelectedRow] = useState<PosDealUserPartyView>();
  const {
    filteredViews,
    handleGetDealUserPartyView,
    dealUserPartyViewResponse
  } = usePosDealUserPartyHooks();
  const {
    isOpen: isOpenPublisher,
    onOpen: onOpenPublisher,
    onClose: onClosePublisher
  } = useDisclosure();
  const {
    isOpen: isOpenUnpublisher,
    onOpen: onOpenUnpublisher,
    onClose: onCloseUnpublisher
  } = useDisclosure();

  useAuthStateChangeHook({
    onAuthStateChange: (user) => {
      if (user) {
        handleGetDealUserPartyView(props.dealId);
      }
    },
    deps: [props.dealId]
  });

  const handleOnPublishToggle = (item: PosDealUserPartyView) => {
    setSelectedRow(item);
    const isDisabled =
      item.publishStatus === PosPublishStatus.Publishing ||
      item.publishStatus === PosPublishStatus.Unpublishing;
    if (isDisabled) {
      return;
    }

    if (
      item.publishStatus === PosPublishStatus.Published ||
      item.publishStatus === PosPublishStatus.FailedToUnpublished
    ) {
      onOpenUnpublisher();
    }

    if (
      !item.publishStatus ||
      item.publishStatus === PosPublishStatus.Unpublished ||
      item.publishStatus === PosPublishStatus.FailedToPublished
    ) {
      onOpenPublisher();
    }
  };

  return (
    <>
      <ElphiPaginationList
        height={heightOffsetInPx(320)}
        tableName={"pos-users"}
        isLoading={dealUserPartyViewResponse.isLoading}
        rowStyle={{
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none"
        }}
        header={
          <TableRowSizeComponent
            bgColor="gray.100"
            row={headerCells}
            withBorderRightWidth={false}
            justify="space-between"
          />
        }
        pageSize={10}
        items={filteredViews}
        next={() => {}}
        hasMore={false}
        options={{
          showEndMessage: false,
          showWatching: false,
          showLoader: true,
          showFetchMore: false
        }}
        rowBuilder={(row, index) => {
          return row ? (
            <PosUserRow
              key={index}
              index={Number(index)}
              data={row}
              publishToggleOnClick={handleOnPublishToggle}
            />
          ) : (
            <></>
          );
        }}
      />
      {selectedRow && (
        <>
          <PublishModal
            show={isOpenPublisher}
            onClose={onClosePublisher}
            {...props}
            data={selectedRow}
          />
          <UnpublishModal
            show={isOpenUnpublisher}
            onClose={onCloseUnpublisher}
            {...props}
            data={selectedRow}
          />
        </>
      )}
    </>
  );
};

const headerCells: ElphiTableProps["header"] = [
  {
    index: 0,
    type: ElphiCellType.String,
    size: SIZE_FIELD.S,
    data: "Actions",
    ...baseCellStyle
  },
  {
    index: 1,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "POS User Name",
    ...baseCellStyle
  },
  {
    index: 2,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "POS User Email",
    ...baseCellStyle
  },
  {
    index: 3,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "LOS Party Name",
    ...baseCellStyle
  },
  {
    index: 4,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "POS User Status",
    ...baseCellStyle
  },
  {
    index: 5,
    type: ElphiCellType.String,
    size: SIZE_FIELD.L,
    data: "Loan Publish Status",
    ...baseCellStyle
  }
];
