import { Box, Flex } from "@chakra-ui/react";
import { AggregationType, FieldStatus, FieldType } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import lodash, { get, isEmpty } from "lodash";
import { EMPTY } from "../../constants/common";
import { AllEventsMode } from "../../redux/v2/audit-event";
import { AuditLogBox } from "../audit-log/AuditLogBox";
import { fieldStatusToFieldColor } from "../task/field-selection/d2v.utils";
import {
  areAllValuesInOptionsValidation,
  isAnyValuesInOptionsValidation
} from "../utils/validationUtils";
import { AttachedAggregationFieldComponet } from "./AttachedAggregationFieldComponent";
import { AttachedFieldStatusComponet } from "./AttachedFieldStatusComponent";
import { InputBuilderSpecs, OnChangeInput } from "./FormBuilder";
import { EntityFormFieldSpecs } from "./field-specs/fields.types";
import {
  getFieldStatusFromState,
  getValueFromState
} from "./field-specs/objectKeyValidation.types";
import { BaseInputFormatter } from "./formatters/formatter.types";
import { formatterMap } from "./formatters/inputs.formatter";

type Specs = {
  isAggregation?: AggregationType;
  path: any;
  validation?: (v: any) => boolean;
  isHidden?: boolean;
  isReadOnly?: any;
  isDisabled?: boolean;
  formatter?: BaseInputFormatter<FieldType, any, any>;
  isRequired?: boolean;
};

type Input = InputBuilderSpecs & {
  id?: string;
  isDisabled?: boolean;
  fieldPath: any;
};

export const buildInputs = <T extends object>(r: {
  id?: string;
  state: T;
  prefixPathToValue?: string[];
  fieldSpecs: EntityFormFieldSpecs<object>;
  onChange: ((v: OnChangeInput) => void) | undefined;
  specs: Specs[];
  hideAttachedComponent?: boolean;
}): Input[] => {
  const {
    state,
    fieldSpecs,
    onChange,
    hideAttachedComponent,
    prefixPathToValue
  } = r;
  return r.specs
    .map((spec): Input | undefined => {
      const focusState = get(state, [...spec.path, "focused"]);

      const aggregationFocusedField =
        focusState === "override"
          ? "override"
          : focusState === "calculated"
          ? "calculated"
          : [
              AggregationType.Aggregation,
              AggregationType.ThirdPartyAggregation,
              undefined
            ].includes(spec?.isAggregation)
          ? "calculated"
          : "thirdParty";
      const selectedFieldPath = spec.isAggregation
        ? [...spec.path, aggregationFocusedField]
        : spec.path;

      const fieldSpec = (get(fieldSpecs, selectedFieldPath) ||
        {}) as InputBuilderSpecs;

      if (isEmpty(fieldSpec)) {
        return undefined;
      }

      const value = getValueFromState({
        state,
        fieldPath: prefixPathToValue
          ? [...prefixPathToValue, ...(selectedFieldPath || [])]
          : selectedFieldPath
      });
      const status: FieldStatus | undefined = getFieldStatusFromState({
        state,
        fieldPath: spec.path as any
      });

      const fieldStatusAttachment = {
        fieldPath: spec.path,
        prefix: [],
        state,
        isReadOnly: spec.isReadOnly,
        isDisabled: spec.isDisabled,
        onChange: onChange || (() => {})
      };
      const id = get(state, "id") || EMPTY;
      const hasMoreThanOneFocus =
        get(fieldSpecs, [...spec.path, "focused", "options"])?.length > 1;

      return {
        id: r.id,
        fieldPath: spec.path,
        fieldStatus: status,
        formatter: spec.formatter || formatterMap[fieldSpec.fieldType],
        labelPosition: "up",
        ...fieldSpec,
        isAggregation: !!spec.isAggregation,
        isValid:
          spec.validation &&
          spec.validation(value) &&
          additionalValidations(fieldSpec, value),
        currentValue: value,
        customColor: status && fieldStatusToFieldColor[status],
        isReadOnly: isReadOnly({ status }) || spec.isReadOnly,
        isDisabled: spec.isDisabled,
        isHidden: spec.isHidden,
        shouldMarkEmpty: spec.isAggregation && !value,
        isRequired: spec.isRequired,
        shouldShowFocusBadge: hasMoreThanOneFocus,
        attachedComponent: hideAttachedComponent ? null : (
          <Flex>
            <Box>
              {spec.isAggregation && hasMoreThanOneFocus ? (
                <AttachedAggregationFieldComponet
                  {...fieldStatusAttachment}
                  aggregationType={spec.isAggregation}
                />
              ) : null}
            </Box>
            <Box>
              <AttachedFieldStatusComponet {...fieldStatusAttachment} />
            </Box>
            {spec.path && (
              <Box paddingTop={"6px"} paddingLeft={"2px"}>
                <AuditLogBox
                  type={AllEventsMode.Field}
                  path={spec.path}
                  title={fieldSpec.label}
                  aggregationType={spec.isAggregation}
                  entityId={String(id)}
                />
              </Box>
            )}
          </Flex>
        )
      };
    })
    .filter(removeEmpty);
};
export const isReadOnly = (r: { status?: FieldStatus }) => {
  return r.status === FieldStatus.Approved;
};

export type AdditionalValidationsInputType = Pick<
  InputBuilderSpecs,
  "fieldType" | "options" | "isRequired" | "componentOverride"
>;

export const additionalValidations = (
  input: AdditionalValidationsInputType,
  value: any
) => {
  if (input.componentOverride) {
    return true;
  }
  switch (input.fieldType) {
    case FieldType.SingleSelect:
    case FieldType.Boolean:
      return isAnyValuesInOptionsValidation(
        [value],
        input.options,
        input.isRequired
      );
    case FieldType.MultiSelect: {
      return areAllValuesInOptionsValidation(
        value,
        input.options,
        input.isRequired
      );
    }
    default:
      return true;
  }
};

export const fieldStatusComponentFieldSpread = <T extends object>(r: {
  state: T;
  prefix: string[];
  fieldPath: string[];
  isReadOnly?: boolean;
}) => {
  const { state, prefix, fieldPath } = r;
  const metaFieldPath = [...prefix, "fieldMeta", ...fieldPath, "status"];
  const status = lodash.get(state, metaFieldPath);

  const fieldStatusCommonFields: Pick<
    InputBuilderSpecs,
    "isReadOnly" | "customColor" | "fieldStatus"
  > = {
    isReadOnly: isReadOnly({ status }) || r.isReadOnly,
    customColor: fieldStatusToFieldColor[status],
    fieldStatus: status
  };
  return fieldStatusCommonFields;
};
