import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  AggregationFundingShieldFields,
  FundingShieldFlatFields
} from "../../../../../../types/entities/integrations";

export const getFundingShieldWireInsuranceCertAggregationTempate =
  (): AggregationFundingShieldFields => {
    const thirdPartyTemplate = thirdPartyFieldTemplate();
    const aggregationTemplate = aggregationFieldTemplate();

    return {
      TransactionId: thirdPartyTemplate,
      TransactionStatus: thirdPartyTemplate,
      StateNYIndicator: aggregationTemplate
    };
  };

export const getFundingShieldWireInsuranceCertTempate =
  (): FundingShieldFlatFields => {
    return {
      rushOrderIndicator: "" as BooleanSelectType,
      userEmail: "",
      userFirstName: "",
      userLastName: ""
    };
  };
