import { merge, omit } from "lodash";
import { BaseSchemaDeal } from "../../../../../../types/entities/base";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import { MofinDealFields } from "../../../../../../types/entities/mofin/deal";
import {
  mofinACHStatusType,
  mofinChannelType,
  mofinInsuranceMortgageeStatusType,
  mofinInvestorIdentifierType,
  mofinLenderIdentifierType,
  mofinOperationsDepartmentType,
  mofinPaymentDueDayType,
  mofinServicerIdentifierType,
  mofinWarehouseBankStatusTypeType,
  mofinWarehouseBankTypeType
} from "../../../../../../types/entities/mofin/deal/mofinDeal.enumerations";
import {
  IntegrationType,
  PricingVendorType
} from "../../../../../../types/entities/task-types";
import { WireInsuranceVendorType } from "../../../../../../types/entities/task-types/integrations/wire-insurance-certificate";
import { DotNestedKeys } from "../../../../../../types/utils/flatten";
import { getLoanpassPricingTemplate } from "../../integrations/deal";
import {
  getFundingShieldWireInsuranceCertAggregationTempate,
  getFundingShieldWireInsuranceCertTempate
} from "../../integrations/property";
import { getBaseSchemaDealTemplate } from "../base-schema";

export const mofinDealBaseFieldsToOmit: DotNestedKeys<
  Partial<BaseSchemaDeal>
>[] = [
  "DealMetadata.milestone",
  "DownPaymentAmount",
  "TitleCommitment.TitleInternationalExecutionIndicator",
  "SellerHUDAddedIndicator",
  "PurchaserWarrantyDeedAddedIndicator",
  "UCCFiledIndicator",
  "UCCExpirationDate",
  "Ledger.NewYorkProcessingFeeAmount",
  "ServicerIdentifier",
  "InvestorIdentifier",
  "LenderIdentifier",
  "OperationsDepartment",
  "PaymentDueDay",
  "WarehouseBankType",
  "WarehouseBankStatusType",
  "Channel"
];

export const getMofinSchemaDealTemplate = (): MofinDealFields => {
  const baseDeal = omit(getBaseSchemaDealTemplate(), mofinDealBaseFieldsToOmit);
  const mofinDealFields: MofinDealFields = {
    ACHStatus: "" as OptionsOf<typeof mofinACHStatusType>,
    Channel: "" as OptionsOf<typeof mofinChannelType>,
    FinalTitlePolicyToCustodianDate: "",
    InsuranceMortgageeRequestDate: "",
    InsuranceMortgageeRequestFollowUpDate: "",
    InsuranceMortgageeStatus: "" as OptionsOf<
      typeof mofinInsuranceMortgageeStatusType
    >,
    Integrations: {
      WireInsuranceCertificate: {
        FundingShield: getFundingShieldWireInsuranceCertTempate()
      }
    },
    LenderIdentifier: "" as OptionsOf<typeof mofinLenderIdentifierType>,
    MERSLoanIndicator: "" as BooleanSelectType,
    OperationsDepartment: "" as OptionsOf<typeof mofinOperationsDepartmentType>,
    PaymentDueDay: "" as OptionsOf<typeof mofinPaymentDueDayType>,
    ReceivedFinalTitlePolicyIndicator: "" as BooleanSelectType,
    ReceivedRecordablesIndicator: "" as BooleanSelectType,
    SentRecordablesToCustodianDate: "",
    ServicerIdentifier: "" as OptionsOf<typeof mofinServicerIdentifierType>,
    WarehouseBankStatusType: "" as OptionsOf<
      typeof mofinWarehouseBankStatusTypeType
    >,
    WarehouseBankType: "" as OptionsOf<typeof mofinWarehouseBankTypeType>,
    aggregations: {
      Integrations: {
        [IntegrationType.Pricing]: {
          [PricingVendorType.Loanpass]: getLoanpassPricingTemplate()
        },
        [IntegrationType.WireInsuranceCertificate]: {
          [WireInsuranceVendorType.FundingShield]:
            getFundingShieldWireInsuranceCertAggregationTempate()
        }
      }
    },
    InvestorIdentifier: "" as OptionsOf<typeof mofinInvestorIdentifierType>
  };
  return merge(baseDeal, mofinDealFields);
};
