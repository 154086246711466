import {
  BaseParty,
  BaseSchemaEntityParty,
  BaseSchemaIndividualParty
} from "../../../../../../types/entities/base";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  MofinBasePartyFields,
  MofinEntityParty,
  MofinIndividualParty
} from "../../../../../../types/entities/mofin/party";
import {
  mofinEntityType,
  mofinOperationsDepartment
} from "../../../../../../types/entities/mofin/party/mofinParty.enumeration";
import { PartyType } from "../../../../../../types/entities/party.types";
import { DotNestedKeys } from "../../../../../../types/utils/flatten";
import { getBaseSchemaPartyPartyTemplate } from "../base-schema/party.template";
import { BaseFields } from "../types";

export const mofinPartyBaseFieldsToOmit: DotNestedKeys<Partial<BaseParty>>[] = [
  "OperationsDepartment",
  "EntityType"
];

const getMofinSchemaCommonPartyTemplate = (): MofinBasePartyFields => {
  return {
    MortgageLatePaymentsIndicator: "" as BooleanSelectType,
    OperationsDepartment: "" as OptionsOf<typeof mofinOperationsDepartment>
  };
};

const getMofinSchemaEntityPartyTemplate = (): Omit<
  MofinEntityParty,
  BaseFields
> => {
  const baseEntityParty = getBaseSchemaPartyPartyTemplate(
    PartyType.Entity
  ) as Omit<BaseSchemaEntityParty, BaseFields>;
  const mofinCommonEntityParty = getMofinSchemaCommonPartyTemplate();

  return {
    ...baseEntityParty,
    ...mofinCommonEntityParty,
    EntityType: "" as OptionsOf<typeof mofinEntityType>
  };
};

const getMofinSchemaPartyIndividualTemplate = (): Omit<
  MofinIndividualParty,
  BaseFields
> => {
  const baseIndividualParty = getBaseSchemaPartyPartyTemplate(
    PartyType.Individual
  ) as Omit<BaseSchemaIndividualParty, BaseFields>;

  const mofinCommonEntityParty = getMofinSchemaCommonPartyTemplate();

  return {
    ...baseIndividualParty,
    ...mofinCommonEntityParty
  };
};

export const getMofinPartyTemplate = (partyType: PartyType) => {
  switch (partyType) {
    case PartyType.Entity:
      return getMofinSchemaEntityPartyTemplate();
    case PartyType.Individual:
      return getMofinSchemaPartyIndividualTemplate();
    default:
      return undefined;
  }
};
