import { aggregationFieldTemplate } from "../../../../../../types/aggregations";
import {
  BaseParty,
  BaseSchemaEntityParty,
  BaseSchemaIndividualParty
} from "../../../../../../types/entities/base";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  LimaBasePartyFields,
  LimaEntityParty,
  LimaEntityPartyFields,
  LimaIndividualParty,
  LimaIndividualPartyFields,
  limaFixNFlipNBridgePlusTier,
  limaLOCStatusType,
  limaPartyMilestone
} from "../../../../../../types/entities/lima";
import { PartyType } from "../../../../../../types/entities/party.types";
import { DotNestedKeys } from "../../../../../../types/utils/flatten";
import { getBaseSchemaPartyPartyTemplate } from "../base-schema";
import { BaseFields } from "../types";

export const limaPartyBaseFieldsToOmit: DotNestedKeys<Partial<BaseParty>>[] = [
  "CitizenshipResidency",
  "CreditScore",
  "CreditReportDate",
  "AuthorizedContacts",
  "NumberOfRehabProjects"
];

const getLimaSchemaCommonPartyTemplate = (): LimaBasePartyFields => {
  const calculatedField = aggregationFieldTemplate();
  return {
    aggregations: {
      LOCExpirationDate: calculatedField
    },
    CRMId: ""
  };
};

const getLimaSchemaEntityPartyTemplate = (): Omit<
  LimaEntityParty,
  BaseFields
> => {
  const baseEntityParty = getBaseSchemaPartyPartyTemplate(
    PartyType.Entity
  ) as Omit<BaseSchemaEntityParty, BaseFields>;
  const limaCommonParty = getLimaSchemaCommonPartyTemplate();
  const limaEntityParty: LimaEntityPartyFields = {
    FixAndFlipBridgePlusTier: "" as OptionsOf<
      typeof limaFixNFlipNBridgePlusTier
    >,
    OverridePartyValidationSettingsIndicator: BooleanSelectType.false,
    PartyMilestone: "" as OptionsOf<typeof limaPartyMilestone>
  };

  return {
    ...baseEntityParty,
    ...limaCommonParty,
    ...limaEntityParty
  };
};

const getLimaSchemaPartyIndividualTemplate = (): Omit<
  LimaIndividualParty,
  BaseFields
> => {
  const baseIndividualParty = getBaseSchemaPartyPartyTemplate(
    PartyType.Individual
  ) as Omit<BaseSchemaIndividualParty, BaseFields>;

  const limaCommonEntityParty = getLimaSchemaCommonPartyTemplate();

  const limaEntityParty: LimaIndividualPartyFields = {
    FixNFlipNBridgePlusTier: "" as OptionsOf<
      typeof limaFixNFlipNBridgePlusTier
    >,
    LOCStatusType: "" as OptionsOf<typeof limaLOCStatusType>,
    PaymentTokenId: ""
  };

  return {
    ...baseIndividualParty,
    ...limaCommonEntityParty,
    ...limaEntityParty
  };
};

export const getLimaPartyTemplate = (partyType: PartyType) => {
  switch (partyType) {
    case PartyType.Entity:
      return getLimaSchemaEntityPartyTemplate();
    case PartyType.Individual:
      return getLimaSchemaPartyIndividualTemplate();
    default:
      return undefined;
  }
};
