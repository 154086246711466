import { aggregationFieldTemplate } from "../../../../types/aggregations";
import { BooleanSelectType } from "../../../../types/entities/fieldFormat.types";
import {
  Statement,
  StatementStatusType
} from "../../../../types/entities/statement.types";

export const getStatementTemplate = () => {
  const statement: Statement = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    assetId: "",
    status: "" as StatementStatusType,
    latestStatement: "" as BooleanSelectType,
    start: "",
    end: "",
    statementBalance: "",
    unverifiedDeposits: "",
    percentConsidered: "",
    aggregations: {
      qualifyingBalance: aggregationFieldTemplate()
    }
  };

  return statement;
};
