import { SystemSetup } from "@elphi/types";
import { ElphiDomain } from "../../../utils/envUtils";

// TODO: env var
export const getDomainOwner = () => {
  const domain = window.location.hostname;
  switch (domain) {
    case ElphiDomain.EdomonTest:
    case ElphiDomain.Localhost:
    case ElphiDomain.ElphiTest:
    case ElphiDomain.ElphiSandbox:
    case ElphiDomain.LedgerTCTest:
    case ElphiDomain.LedgerTCProd:
      return SystemSetup.Elphi;
    case ElphiDomain.LimaPoc:
    case ElphiDomain.LimaProd:
    case ElphiDomain.LimaTest:
    case ElphiDomain.ElamiaTest:
    case ElphiDomain.L1cTest:
      return SystemSetup.Lima;
    case ElphiDomain.MofinTest:
    case ElphiDomain.MofinProd:
      return SystemSetup.Mofin;
    default:
      return SystemSetup.Elphi;
  }
};
