import {
  AggregationFocusType,
  AggregationLeasedFinancedUnitsFieldValueType,
  AppraisalValueDerivationType,
  BillingFrequencyType,
  FieldType,
  InspectionInitialDelayReasonStatusType,
  InspectionOrderType,
  LeaseStrategyType,
  LeasedFinancedUnitKeys,
  PropertyLoanPurpose,
  PropertyRightsOwnershipType,
  PropertyZoningComplianceRatingType
} from "@elphi/types";
import {
  baseAppraisalType,
  baseConditionRating,
  baseConstructionBudgetAssessment,
  baseLeaseStatus,
  basePropertyType
} from "@elphi/types/entities/base";
import {
  BaseLeasedFinancedUnit,
  BaseSchemaProperty
} from "@elphi/types/entities/base/property";
import { nestedAddressFieldSpecs } from "../../../../../components/form-builder/field-specs/address/address.fields";
import { baseEntityFormFieldSpecs } from "../../../../../components/form-builder/field-specs/baseEntity.fields";
import {
  EntityFormFieldSpecs,
  EntityFormFieldSpecsHelper,
  SpecsBaseOmit
} from "../../../../../components/form-builder/field-specs/fields.types";
import { createSpecWithFieldMeta } from "../../../../../components/form-builder/field-specs/utils/fieldMeta.utils";
import {
  booleanOptions,
  createOptionsFromEnumAllowUndefined,
  createOptionsFromMap
} from "../../../../../components/utils/formUtils";

export const generateListUnitAdjustedRentAmount = <
  T extends keyof typeof LeasedFinancedUnitKeys
>(
  key: T
): EntityFormFieldSpecsHelper<
  Partial<AggregationLeasedFinancedUnitsFieldValueType>,
  ["aggregations", "LeasedFinancedUnits", T]
> => {
  return {
    AdjustedRentAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "LeasedFinancedUnits",
          key,
          "AdjustedRentAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Adjusted Rent Amount"
      },
      override: {
        fieldKey: [
          "aggregations",
          "LeasedFinancedUnits",
          key,
          "AdjustedRentAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Adjusted Rent Amount"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "LeasedFinancedUnits",
          key,
          "AdjustedRentAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  };
};

export const baseUnitFieldSpecs: EntityFormFieldSpecs<BaseLeasedFinancedUnit> =
  {
    AdjustedRentAmount: {
      fieldKey: ["AdjustedRentAmount"],
      fieldType: FieldType.Money,
      label: "Unit Adjusted Rent Amount"
    },
    LeaseExpirationDate: {
      fieldKey: ["LeaseExpirationDate"],
      fieldType: FieldType.Date,
      label: "Lease Expiration"
    },
    LeaseStatusType: {
      fieldKey: ["LeaseStatusType"],
      fieldType: FieldType.SingleSelect,
      label: "Lease Status",
      options: createOptionsFromMap(baseLeaseStatus)
    },
    RentReportConfidenceScore: {
      fieldKey: ["RentReportConfidenceScore"],
      fieldType: FieldType.Percentage,
      label: "Rent Report Confidence Score"
    },
    UnitName: {
      fieldKey: ["UnitName"],
      fieldType: FieldType.String,
      label: "Unit Name"
    },
    MonthlyLeaseRentAmount: {
      fieldKey: ["MonthlyLeaseRentAmount"],
      fieldType: FieldType.Money,
      label: "Contracted Monthly Rent"
    },
    MonthlyMarketRentAmount: {
      fieldKey: ["MonthlyMarketRentAmount"],
      fieldType: FieldType.Money,
      label: "Monthly Market Rent"
    }
  };

export type BaseSchemaPropertyFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<Omit<BaseSchemaProperty, "SheetMetadata">>
>;

export const baseSchemaPropertySpecs: BaseSchemaPropertyFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  aggregations: {
    InitialPropertyInsuranceEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Initial Property Insurance Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Initial Property Insurance Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    InitialPropertyInsuranceEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "Initial Property Insurance Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "Initial Property Insurance Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialPropertyInsuranceEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    SpecialAssessmentInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Special Assessment Initial Tax Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Special Assessment Initial Tax Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    SpecialAssessmentInitialTaxEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "Special Assessment Initial Tax Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "Special Assessment Initial Tax Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "SpecialAssessmentInitialTaxEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    CityInitialTaxEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "CityInitialTaxEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "City Initial Tax Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "CityInitialTaxEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "City Initial Tax Escrow (Months)"
      },
      focused: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowMonthCount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    CityInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "City Initial Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      },
      override: {
        fieldKey: ["aggregations", "CityInitialTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "City Initial Tax Escrow"
      }
    },
    CountyInitialTaxEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "County Initial Tax Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "County Initial Tax Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    CountyInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "CountyInitialTaxEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "County Initial Tax Escrow"
      },
      override: {
        fieldKey: ["aggregations", "CountyInitialTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "County Initial Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "CountyInitialTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    InitialFloodInsuranceEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Initial Flood Insurance Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Initial Flood Insurance Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    InitialFloodInsuranceEscrowMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "Initial Flood Insurance Escrow (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "Initial Flood Insurance Escrow (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "InitialFloodInsuranceEscrowMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyLeaseRentAmount: {
      calculated: {
        fieldKey: ["aggregations", "MonthlyLeaseRentAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Contracted Monthly Rent"
      },
      override: {
        fieldKey: ["aggregations", "MonthlyLeaseRentAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Contracted Monthly Rent"
      },
      focused: {
        fieldKey: ["aggregations", "MonthlyLeaseRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalInsuranceEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalInsuranceEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Insurance Escrow"
      },
      override: {
        fieldKey: ["aggregations", "TotalInsuranceEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Insurance Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "TotalInsuranceEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalTaxEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalTaxEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Tax Escrow"
      },
      override: {
        fieldKey: ["aggregations", "TotalTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "TotalTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyMarketRentAmount: {
      calculated: {
        fieldKey: ["aggregations", "MonthlyMarketRentAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      override: {
        fieldKey: ["aggregations", "MonthlyMarketRentAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      focused: {
        fieldKey: ["aggregations", "MonthlyMarketRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    AdjustedRentAmount: {
      calculated: {
        fieldKey: ["aggregations", "AdjustedRentAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Unit Adjusted Rental Income"
      },
      override: {
        fieldKey: ["aggregations", "AdjustedRentAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Unit Adjusted Rental Income"
      },
      focused: {
        fieldKey: ["aggregations", "AdjustedRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    AnnualTaxAmount: {
      calculated: {
        fieldKey: ["aggregations", "AnnualTaxAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Annual Tax Amount"
      },
      override: {
        fieldKey: ["aggregations", "AnnualTaxAmount", "override"],
        fieldType: FieldType.Money,
        label: "Annual Tax Amount"
      },
      focused: {
        fieldKey: ["aggregations", "AnnualTaxAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LeasedFinancedUnits: {
      unit0: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit0),
      unit1: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit1),
      unit2: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit2),
      unit3: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit3),
      unit4: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit4),
      unit5: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit5),
      unit6: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit6),
      unit7: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit7),
      unit8: generateListUnitAdjustedRentAmount(LeasedFinancedUnitKeys.unit8)
    },
    TotalCostAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalCostAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Property Total Cost"
      },
      override: {
        fieldKey: ["aggregations", "TotalCostAmount", "override"],
        fieldType: FieldType.Money,
        label: "Property Total Cost"
      },
      focused: {
        fieldKey: ["aggregations", "TotalCostAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LTCRatePercent: {
      calculated: {
        fieldKey: ["aggregations", "LTCRatePercent", "calculated"],
        fieldType: FieldType.Percentage,
        label: "LTC (%)"
      },
      override: {
        fieldKey: ["aggregations", "LTCRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "LTC (%)"
      },
      focused: {
        fieldKey: ["aggregations", "LTCRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    CostBasisAmount: {
      calculated: {
        fieldType: FieldType.Money,
        label: "Cost Basis",
        fieldKey: ["aggregations", "CostBasisAmount", "calculated"]
      },
      override: {
        fieldType: FieldType.Money,
        label: "Cost Basis",
        fieldKey: ["aggregations", "CostBasisAmount", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "CostBasisAmount", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LoanToValueRatePercent: {
      calculated: {
        fieldType: FieldType.Percentage,
        label: "Loan to Value (LTV) %",
        fieldKey: ["aggregations", "LoanToValueRatePercent", "calculated"]
      },
      override: {
        fieldType: FieldType.Percentage,
        label: "Loan to Value (LTV) %",
        fieldKey: ["aggregations", "LoanToValueRatePercent", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "LoanToValueRatePercent", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    AsIsLoanToValueRatePercent: {
      calculated: {
        fieldType: FieldType.Percentage,
        label: "As-Is LTV %",
        fieldKey: ["aggregations", "AsIsLoanToValueRatePercent", "calculated"]
      },
      override: {
        fieldType: FieldType.Percentage,
        label: "As-Is LTV %",
        fieldKey: ["aggregations", "AsIsLoanToValueRatePercent", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "AsIsLoanToValueRatePercent", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LTARVRatePercent: {
      calculated: {
        fieldType: FieldType.Percentage,
        label: "Loan to After Repair Value (LTARV) %",
        fieldKey: ["aggregations", "LTARVRatePercent", "calculated"]
      },
      override: {
        fieldType: FieldType.Percentage,
        label: "Loan to After Repair Value (LTARV) %",
        fieldKey: ["aggregations", "LTARVRatePercent", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "LTARVRatePercent", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LTTCRatePercent: {
      calculated: {
        fieldType: FieldType.Percentage,
        label: "Loan to Total Cost (LTTC) %",
        fieldKey: ["aggregations", "LTTCRatePercent", "calculated"]
      },
      override: {
        fieldType: FieldType.Percentage,
        label: "Loan to Total Cost (LTTC) %",
        fieldKey: ["aggregations", "LTTCRatePercent", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "LTTCRatePercent", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyTaxEscrowAmount: {
      calculated: {
        fieldType: FieldType.Money,
        label: "Total Monthly Tax Escrow",
        fieldKey: ["aggregations", "MonthlyTaxEscrowAmount", "calculated"]
      },
      override: {
        fieldType: FieldType.Money,
        label: "Total Monthly Tax Escrow",
        fieldKey: ["aggregations", "MonthlyTaxEscrowAmount", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "MonthlyTaxEscrowAmount", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyInsuranceEscrowAmount: {
      calculated: {
        fieldType: FieldType.Money,
        label: "Total Monthly Insurance Escrow",
        fieldKey: ["aggregations", "MonthlyInsuranceEscrowAmount", "calculated"]
      },
      override: {
        fieldType: FieldType.Money,
        label: "Total Monthly Insurance Escrow",
        fieldKey: ["aggregations", "MonthlyInsuranceEscrowAmount", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "MonthlyInsuranceEscrowAmount", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyEscrowPaymentsAmount: {
      calculated: {
        fieldType: FieldType.Money,
        label: "Monthly Escrow Payments",
        fieldKey: ["aggregations", "MonthlyEscrowPaymentsAmount", "calculated"]
      },
      override: {
        fieldType: FieldType.Money,
        label: "Monthly Escrow Payments",
        fieldKey: ["aggregations", "MonthlyEscrowPaymentsAmount", "override"]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: ["aggregations", "MonthlyEscrowPaymentsAmount", "focused"],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ConstructionBudgetHoldbackPercent: {
      calculated: {
        fieldType: FieldType.Percentage,
        label: "% of Construction Budget in Holdback",
        fieldKey: [
          "aggregations",
          "ConstructionBudgetHoldbackPercent",
          "calculated"
        ]
      },
      override: {
        fieldType: FieldType.Percentage,
        label: "% of Construction Budget in Holdback",
        fieldKey: [
          "aggregations",
          "ConstructionBudgetHoldbackPercent",
          "override"
        ]
      },
      focused: {
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        fieldKey: [
          "aggregations",
          "ConstructionBudgetHoldbackPercent",
          "focused"
        ],
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  Address: {
    FullAddressName: {
      fieldKey: ["Address", "FullAddressName"],
      fieldType: FieldType.String,
      label: "Full Property Address"
    },
    LegalDescription: {
      fieldKey: ["Address", "LegalDescription"],
      fieldType: FieldType.RichText,
      label: "Legal Description"
    },
    PropertyBlockIdentifier: {
      fieldKey: ["Address", "PropertyBlockIdentifier"],
      fieldType: FieldType.String,
      label: "Block"
    },
    PropertyIdentifier: {
      fieldKey: ["Address", "PropertyIdentifier"],
      fieldType: FieldType.String,
      label: "Property ID"
    },
    PropertyLotIdentifier: {
      fieldKey: ["Address", "PropertyLotIdentifier"],
      fieldType: FieldType.String,
      label: "Lot Number"
    },
    PropertySubdivisionIdentifier: {
      fieldKey: ["Address", "PropertySubdivisionIdentifier"],
      fieldType: FieldType.String,
      label: "Subdivision"
    },
    AddressLineText: nestedAddressFieldSpecs.Address.AddressLineText,
    AddressUnitIdentifier:
      nestedAddressFieldSpecs.Address.AddressUnitIdentifier,
    CityName: nestedAddressFieldSpecs.Address.CityName,
    CountyName: nestedAddressFieldSpecs.Address.CountyName,
    PostalCode: nestedAddressFieldSpecs.Address.PostalCode,
    StateCode: nestedAddressFieldSpecs.Address.StateCode,
    StateName: nestedAddressFieldSpecs.Address.StateName
  },
  AllocatedRDSRRatePercent: {
    fieldKey: ["AllocatedRDSRRatePercent"],
    fieldType: FieldType.Percentage,
    label: "Allocated RDSR"
  },
  AnnualHomeownersAssociationFeeAmount: {
    fieldKey: ["AnnualHomeownersAssociationFeeAmount"],
    fieldType: FieldType.Money,
    label: "Annual HOA Fees"
  },
  AnnualTaxesAmount: {
    fieldKey: ["AnnualTaxesAmount"],
    fieldType: FieldType.Money,
    label: "Annual Taxes ($)"
  },
  Appraisal: {
    AppraisalCompletedDate: {
      fieldKey: ["Appraisal", "AppraisalCompletedDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Completed Date"
    },
    AppraisalDate: {
      fieldKey: ["Appraisal", "AppraisalDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Date"
    },
    AppraisalEffectiveDate: {
      fieldKey: ["Appraisal", "AppraisalEffectiveDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Effective Date"
    },
    AppraisalExpirationDate: {
      fieldKey: ["Appraisal", "AppraisalExpirationDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Expiration Date"
    },
    AppraisalForm1007RequiredIndicator: {
      fieldKey: ["Appraisal", "AppraisalForm1007RequiredIndicator"],
      fieldType: FieldType.Boolean,
      label: "Is 1007 Needed?",
      options: booleanOptions
    },
    AppraisalOrderedDate: {
      fieldKey: ["Appraisal", "AppraisalOrderedDate"],
      fieldType: FieldType.Date,
      label: "Appraisal Ordered Date"
    },
    AppraisalType: {
      fieldKey: ["Appraisal", "AppraisalType"],
      fieldType: FieldType.SingleSelect,
      label: "Appraisal Type",
      options: createOptionsFromMap(baseAppraisalType)
    },
    AppraisalValueDerivationType: {
      fieldKey: ["Appraisal", "AppraisalValueDerivationType"],
      fieldType: FieldType.SingleSelect,
      label: "Appraisal Methodology",
      options: createOptionsFromMap(AppraisalValueDerivationType)
    },
    ConditionRatingType: {
      fieldKey: ["Appraisal", "ConditionRatingType"],
      fieldType: FieldType.SingleSelect,
      label: "Condition",
      options: createOptionsFromMap(baseConditionRating)
    },
    PropertyAccessInformation: {
      fieldKey: ["Appraisal", "PropertyAccessInformation"],
      fieldType: FieldType.RichText,
      label: "Property Access Information"
    },
    PropertyAreaValue: {
      fieldKey: ["Appraisal", "PropertyAreaValue"],
      fieldType: FieldType.Number,
      label: "Square Foot"
    },
    PropertyBuiltYear: {
      fieldKey: ["Appraisal", "PropertyBuiltYear"],
      fieldType: FieldType.Year,
      label: "Year Built"
    },
    PropertyStructureBuiltYear: {
      fieldKey: ["Appraisal", "PropertyStructureBuiltYear"],
      fieldType: FieldType.Year,
      label: "Property Year Built"
    },
    PropertyValuationAmount: {
      fieldKey: ["Appraisal", "PropertyValuationAmount"],
      fieldType: FieldType.Money,
      label: "As-Is Appraised Value"
    },
    PropertyZoningComplianceRatingType: {
      fieldKey: ["Appraisal", "PropertyZoningComplianceRatingType"],
      fieldType: FieldType.SingleSelect,
      label: "Zoning Compliance",
      options: createOptionsFromMap(PropertyZoningComplianceRatingType)
    },
    PropertyZoningDescription: {
      fieldKey: ["Appraisal", "PropertyZoningDescription"],
      fieldType: FieldType.String,
      label: "Zoning Description"
    },
    SpecificZoningClassification: {
      fieldKey: ["Appraisal", "SpecificZoningClassification"],
      fieldType: FieldType.String,
      label: "Specific Zoning Classification"
    },
    SubjectToPropertyValuationAmount: {
      fieldKey: ["Appraisal", "SubjectToPropertyValuationAmount"],
      fieldType: FieldType.Money,
      label: "Subject-to Appraised Value"
    },
    TotalBathroomCount: {
      fieldKey: ["Appraisal", "TotalBathroomCount"],
      fieldType: FieldType.Decimal,
      label: "Bathrooms"
    },
    TotalBedroomCount: {
      fieldKey: ["Appraisal", "TotalBedroomCount"],
      fieldType: FieldType.Decimal,
      label: "Bedrooms"
    }
  },
  AppraisalManagementCompany: {
    ContactPointEmailValue: {
      fieldKey: ["AppraisalManagementCompany", "ContactPointEmailValue"],
      fieldType: FieldType.Email,
      label: "Point of Contact Email"
    },
    ContactPointTelephoneValue: {
      fieldKey: ["AppraisalManagementCompany", "ContactPointTelephoneValue"],
      fieldType: FieldType.Phone,
      label: "Point of Contact Phone Number"
    },
    RepresentativeFullName: {
      fieldKey: ["AppraisalManagementCompany", "RepresentativeFullName"],
      fieldType: FieldType.String,
      label: "Point of Contact Name"
    },
    AppraisalManagementCompanyName: {
      fieldKey: [
        "AppraisalManagementCompany",
        "AppraisalManagementCompanyName"
      ],
      fieldType: FieldType.String,
      label: "Appraisal Management Company"
    }
  },
  CapitalExpenditureAmount: {
    fieldKey: ["CapitalExpenditureAmount"],
    fieldType: FieldType.Money,
    label: "Capital Expenditure "
  },
  CityAnnualTaxAmount: {
    fieldKey: ["CityAnnualTaxAmount"],
    fieldType: FieldType.Money,
    label: "City Annual Taxes"
  },
  CityTaxBillingFrequencyType: {
    fieldKey: ["CityTaxBillingFrequencyType"],
    fieldType: FieldType.SingleSelect,
    label: "City Tax Billing Frequency",
    options: createOptionsFromMap(BillingFrequencyType)
  },
  CityTaxDueDate: {
    fieldKey: ["CityTaxDueDate"],
    fieldType: FieldType.Date,
    label: "CIty Tax Due Date"
  },
  CityTaxingAuthority: {
    fieldKey: ["CityTaxingAuthority"],
    fieldType: FieldType.String,
    label: "City Taxing Authority"
  },
  CondoWarrantableIndicator: {
    fieldKey: ["CondoWarrantableIndicator"],
    fieldType: FieldType.Boolean,
    label: "Condo Warrantable",
    options: booleanOptions
  },
  ConstructionCostAmount: {
    fieldKey: ["ConstructionCostAmount"],
    fieldType: FieldType.Money,
    label: "Construction Budget"
  },
  ConstructionCostComments: {
    fieldKey: ["ConstructionCostComments"],
    fieldType: FieldType.RichText,
    label: "Construction Budget Comments"
  },
  ConstructionHoldbackAmount: {
    fieldKey: ["ConstructionHoldbackAmount"],
    fieldType: FieldType.Money,
    label: "Construction Holdback Amount"
  },
  GroundLeaseExpirationDate: {
    fieldKey: ["GroundLeaseExpirationDate"],
    fieldType: FieldType.Date,
    label: "Ground Lease Expiration Date"
  },
  ContractClosingDate: {
    fieldKey: ["ContractClosingDate"],
    fieldType: FieldType.Date,
    label: "Contract Closing Date"
  },
  CountyAnnualTaxAmount: {
    fieldKey: ["CountyAnnualTaxAmount"],
    fieldType: FieldType.Money,
    label: "County Annual Taxes"
  },
  CountyTaxBillingFrequencyType: {
    fieldKey: ["CountyTaxBillingFrequencyType"],
    fieldType: FieldType.SingleSelect,
    label: "County Tax Billing Frequency",
    options: createOptionsFromMap(BillingFrequencyType)
  },
  CountyTaxDueDate: {
    fieldKey: ["CountyTaxDueDate"],
    fieldType: FieldType.Date,
    label: "County Tax Due Date"
  },
  CountyTaxingAuthority: {
    fieldKey: ["CountyTaxingAuthority"],
    fieldType: FieldType.String,
    label: "County Taxing Authority"
  },
  DeferredMaintenanceAmount: {
    fieldKey: ["DeferredMaintenanceAmount"],
    fieldType: FieldType.Money,
    label: "Deferred Maintenance"
  },
  FinancedUnitCount: {
    fieldKey: ["FinancedUnitCount"],
    fieldType: FieldType.Number,
    label: "Units"
  },
  LeasedFinancedUnits: {
    unit0: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit0", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit0", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit0", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit0", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit0", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit0", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit0", "UnitName"]
      }
    },
    unit1: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit1", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit1", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit1", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit1", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit1", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit1", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit1", "UnitName"]
      }
    },
    unit2: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit2", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit2", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit2", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit2", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit2", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit2", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit2", "UnitName"]
      }
    },
    unit3: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit3", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit3", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit3", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit3", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit3", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit3", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit3", "UnitName"]
      }
    },
    unit4: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit4", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit4", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit4", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit4", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit4", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit4", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit4", "UnitName"]
      }
    },
    unit5: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit5", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit5", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit5", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit5", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit5", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit5", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit5", "UnitName"]
      }
    },
    unit6: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit6", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit6", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit6", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit6", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit6", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit6", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit6", "UnitName"]
      }
    },
    unit7: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit7", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit7", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit7", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit7", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit7", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit7", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit7", "UnitName"]
      }
    },
    unit8: {
      AdjustedRentAmount: {
        ...baseUnitFieldSpecs.AdjustedRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit8", "AdjustedRentAmount"]
      },
      LeaseExpirationDate: {
        ...baseUnitFieldSpecs.LeaseExpirationDate,
        fieldKey: ["LeasedFinancedUnits", "unit8", "LeaseExpirationDate"]
      },
      LeaseStatusType: {
        ...baseUnitFieldSpecs.LeaseStatusType,
        fieldKey: ["LeasedFinancedUnits", "unit8", "LeaseStatusType"]
      },
      MonthlyLeaseRentAmount: {
        ...baseUnitFieldSpecs.MonthlyLeaseRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit8", "MonthlyLeaseRentAmount"]
      },
      MonthlyMarketRentAmount: {
        ...baseUnitFieldSpecs.MonthlyMarketRentAmount,
        fieldKey: ["LeasedFinancedUnits", "unit8", "MonthlyMarketRentAmount"]
      },
      RentReportConfidenceScore: {
        ...baseUnitFieldSpecs.RentReportConfidenceScore,
        fieldKey: ["LeasedFinancedUnits", "unit8", "RentReportConfidenceScore"]
      },
      UnitName: {
        ...baseUnitFieldSpecs.UnitName,
        fieldKey: ["LeasedFinancedUnits", "unit8", "UnitName"]
      }
    }
  },
  LeaseExpirationDate: {
    fieldKey: ["LeaseExpirationDate"],
    fieldType: FieldType.Date,
    label: "Lease Expiration"
  },
  LeaseStatusType: {
    fieldKey: ["LeaseStatusType"],
    fieldType: FieldType.SingleSelect,
    label: "Lease Status",
    options: createOptionsFromMap(baseLeaseStatus)
  },
  NetFundAmount: {
    fieldKey: ["NetFundAmount"],
    fieldType: FieldType.Money,
    label: "Net Fund ($)"
  },
  NetFundRatePercent: {
    fieldKey: ["NetFundRatePercent"],
    fieldType: FieldType.Percentage,
    label: "Net Fund/As-Is (%)"
  },
  NetWireAmount: {
    fieldType: FieldType.Money,
    label: "Net Wire Amount",
    fieldKey: ["NetWireAmount"]
  },
  OriginalPurchaseDate: {
    fieldKey: ["OriginalPurchaseDate"],
    fieldType: FieldType.Date,
    label: "Original Purchase Date"
  },
  OriginalPurchasePriceAmount: {
    fieldKey: ["OriginalPurchasePriceAmount"],
    fieldType: FieldType.Money,
    label: "Original Purchase Price"
  },
  OutstandingLoanPayoffAmount: {
    fieldKey: ["OutstandingLoanPayoffAmount"],
    fieldType: FieldType.Money,
    label: "Outstanding Mortgage Payoff"
  },
  ParcelNumber: {
    fieldKey: ["ParcelNumber"],
    fieldType: FieldType.String,
    label: "Assessors Parcel Number (APN)"
  },
  PayoffExpirationDate: {
    fieldKey: ["PayoffExpirationDate"],
    fieldType: FieldType.Date,
    label: "Payoff Expiration Date"
  },
  PropertyInspection: {
    InspectionDocumentsReceivedDate: {
      fieldKey: ["PropertyInspection", "InspectionDocumentsReceivedDate"],
      fieldType: FieldType.Date,
      label: "Inspection Documents Received Date"
    },
    InspectionInitialDelayReasonStatusType: {
      fieldKey: [
        "PropertyInspection",
        "InspectionInitialDelayReasonStatusType"
      ],
      fieldType: FieldType.MultiSelect,
      label: "Inspection Initial Delay Reason Status Type",
      options: createOptionsFromMap(InspectionInitialDelayReasonStatusType)
    },
    InspectionInitialRequestDate: {
      fieldKey: ["PropertyInspection", "InspectionInitialRequestDate"],
      fieldType: FieldType.Date,
      label: "Inspection Initial Request Date"
    },
    InspectionOrderProcessedDate: {
      fieldKey: ["PropertyInspection", "InspectionOrderProcessedDate"],
      fieldType: FieldType.Date,
      label: "Inspection Order Processed Date"
    },
    InspectionOrderReceivedDate: {
      fieldKey: ["PropertyInspection", "InspectionOrderReceivedDate"],
      fieldType: FieldType.Date,
      label: "Inspection Order Received Date"
    },
    InspectionOrderType: {
      fieldKey: ["PropertyInspection", "InspectionOrderType"],
      fieldType: FieldType.SingleSelect,
      label: "Inspection Order Type",
      options: createOptionsFromMap(InspectionOrderType)
    },
    InspectionReportDueDate: {
      fieldKey: ["PropertyInspection", "InspectionReportDueDate"],
      fieldType: FieldType.Date,
      label: "Inspection Report Due Date"
    },
    InspectorFullName: {
      fieldKey: ["PropertyInspection", "InspectorFullName"],
      fieldType: FieldType.String,
      label: "Inspector Full Name"
    },
    ConstructionAnalystFullName: {
      fieldKey: ["PropertyInspection", "ConstructionAnalystFullName"],
      fieldType: FieldType.String,
      label: "Construction Analyst Full Name"
    },
    InspectionRequestOrderedDate: {
      fieldKey: ["PropertyInspection", "InspectionRequestOrderedDate"],
      fieldType: FieldType.Date,
      label: "Inspection Request Ordered Date"
    }
  },
  PropertyLoanPurpose: {
    fieldKey: ["PropertyLoanPurpose"],
    fieldType: FieldType.SingleSelect,
    label: "Loan Purpose",
    options: createOptionsFromMap(PropertyLoanPurpose)
  },
  PropertyRightsOwnershipType: {
    fieldKey: ["PropertyRightsOwnershipType"],
    fieldType: FieldType.SingleSelect,
    label: "Ownership (Property Rights)",
    options: createOptionsFromMap(PropertyRightsOwnershipType)
  },
  PropertyType: {
    fieldKey: ["PropertyType"],
    fieldType: FieldType.SingleSelect,
    label: "Property Type",
    options: createOptionsFromMap(basePropertyType)
  },
  PSAAssignmentContractIndicator: {
    fieldKey: ["PSAAssignmentContractIndicator"],
    fieldType: FieldType.Boolean,
    label: "PSA Assignment Contract",
    options: booleanOptions
  },
  AssignmentFeeAmount: {
    fieldKey: ["AssignmentFeeAmount"],
    fieldType: FieldType.Money,
    label: "Assignment Fee Amount"
  },
  PSAExtensionRequestedIndicator: {
    fieldKey: ["PSAExtensionRequestedIndicator"],
    fieldType: FieldType.Boolean,
    label: "Extension Requested?",
    options: booleanOptions
  },
  PSASignedIndicator: {
    fieldKey: ["PSASignedIndicator"],
    fieldType: FieldType.Boolean,
    label: "PSA Signed?",
    options: booleanOptions
  },
  PSAExpirationDate: {
    fieldKey: ["PSAExpirationDate"],
    fieldType: FieldType.Date,
    label: "PSA Expiration Date"
  },
  PurchasePriceAmount: {
    fieldKey: ["PurchasePriceAmount"],
    fieldType: FieldType.Money,
    label: "Purchase Price"
  },
  RenovationCostAmount: {
    fieldKey: ["RenovationCostAmount"],
    fieldType: FieldType.Money,
    label: "Renovation Budget"
  },
  RentReportConfidenceScorePercent: {
    fieldKey: ["RentReportConfidenceScorePercent"],
    fieldType: FieldType.Percentage,
    label: "Rent Report Confidence Score"
  },
  SpecialAssessmentAnnualTaxAmount: {
    fieldKey: ["SpecialAssessmentAnnualTaxAmount"],
    fieldType: FieldType.Money,
    label: "Special Assessment Annual Taxes"
  },
  SpecialAssessmentTaxBillingFrequencyType: {
    fieldKey: ["SpecialAssessmentTaxBillingFrequencyType"],
    fieldType: FieldType.SingleSelect,
    label: "Special Assessment Tax Billing Frequency",
    options: createOptionsFromMap(BillingFrequencyType)
  },
  SpecialAssessmentTaxDueDate: {
    fieldKey: ["SpecialAssessmentTaxDueDate"],
    fieldType: FieldType.Date,
    label: "Special Assessment Tax Due Date"
  },
  SpecialAssessmentTaxingAuthority: {
    fieldKey: ["SpecialAssessmentTaxingAuthority"],
    fieldType: FieldType.String,
    label: "Special Assessment Taxing Authority"
  },
  TaxDueDate: {
    fieldKey: ["TaxDueDate"],
    fieldType: FieldType.Date,
    label: "Tax Due Date"
  },
  TotalRentalIncomeAmount: {
    fieldKey: ["TotalRentalIncomeAmount"],
    fieldType: FieldType.Money,
    label: "Total Rental Income"
  },
  BorrowerRenovationCostsAfterPurchaseAmount: {
    fieldType: FieldType.Money,
    label: "Borrower Renovation Costs After Purchase",
    fieldKey: ["BorrowerRenovationCostsAfterPurchaseAmount"]
  },
  LeaseStrategy: {
    fieldKey: ["LeaseStrategy"],
    fieldType: FieldType.SingleSelect,
    label: "Lease Strategy",
    options: createOptionsFromMap(LeaseStrategyType)
  },
  ConstructionBudgetAssessment: {
    fieldKey: ["ConstructionBudgetAssessment"],
    fieldType: FieldType.SingleSelect,
    label: "Construction Budget Assessment",
    options: createOptionsFromMap(baseConstructionBudgetAssessment)
  },
  AllocatedLoanAmount: {
    fieldKey: ["AllocatedLoanAmount"],
    fieldType: FieldType.Money,
    label: "Allocated Loan Amount"
  },
  PropertyInsurance: {
    InsurancePremiumAmount: {
      fieldKey: ["PropertyInsurance", "InsurancePremiumAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Premium Amount"
    },
    InsurancePremiumOutstandingAmount: {
      fieldKey: ["PropertyInsurance", "InsurancePremiumOutstandingAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Premium Amount Outstanding"
    },
    NextPaymentDueDate: {
      fieldKey: ["PropertyInsurance", "NextPaymentDueDate"],
      fieldType: FieldType.Date,
      label: "Property Insurance Next Payment Due Date"
    },
    MonthlyInsurancePremiumAmount: {
      fieldKey: ["PropertyInsurance", "MonthlyInsurancePremiumAmount"],
      fieldType: FieldType.Money,
      label: "Property Insurance Monthly Premium Amount"
    }
  },
  FloodInsurance: {
    InsurancePremiumAmount: {
      fieldKey: ["FloodInsurance", "InsurancePremiumAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Premium Amount"
    },
    InsurancePremiumOutstandingAmount: {
      fieldKey: ["FloodInsurance", "InsurancePremiumOutstandingAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Premium Amount Outstanding"
    },
    NextPaymentDueDate: {
      fieldKey: ["FloodInsurance", "NextPaymentDueDate"],
      fieldType: FieldType.Date,
      label: "Flood Insurance Next Payment Due Date"
    },
    MonthlyInsurancePremiumAmount: {
      fieldKey: ["FloodInsurance", "MonthlyInsurancePremiumAmount"],
      fieldType: FieldType.Money,
      label: "Flood Insurance Monthly Premium Amount"
    }
  },
  ClosingCostsAmount: {
    fieldType: FieldType.Money,
    label: "Closing Costs",
    fieldKey: ["ClosingCostsAmount"]
  },
  InitialFundedAmount: {
    fieldType: FieldType.Money,
    label: "Initial Funded Amount",
    fieldKey: ["InitialFundedAmount"]
  },
  CityMonthlyTaxAmount: {
    fieldKey: ["CityMonthlyTaxAmount"],
    fieldType: FieldType.Money,
    label: "City Monthly Taxes"
  },
  CountyMonthlyTaxAmount: {
    fieldKey: ["CountyMonthlyTaxAmount"],
    fieldType: FieldType.Money,
    label: "County Monthly Taxes"
  },
  SpecialAssessmentMonthlyTaxAmount: {
    fieldKey: ["SpecialAssessmentMonthlyTaxAmount"],
    fieldType: FieldType.Money,
    label: "Special Assessment Monthly Taxes"
  }
};

export const fullBaseSchemaPropertySpecs = createSpecWithFieldMeta({
  spec: baseSchemaPropertySpecs
});
