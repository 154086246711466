import {
  DealAggregation,
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../types/aggregations";
import { OmitCreate, OmitUpdate } from "../../../../types/db";
import {
  ARMRepriceLookbackType,
  ARMRepriceRoundingMethodType,
  ARMRepriceRoundingRatePercentType,
  AmortizationType,
  CapitalStructureType,
  ChannelType,
  CollateralPackageStatusType,
  DealMilestoneType,
  ExecutionType,
  FundingShieldStatusType,
  IndexSourceType,
  InterestAccrualMethodType,
  LenderEntityType,
  LenderIdentifierType,
  LoanPaymentType,
  LoanPurposeType,
  PaymentDueDayType,
  PricingEngineExceptionStatusType,
  PropertyManagerType,
  PropertyModelType,
  RealEstateProgramType,
  RecourseType,
  ShippingCompanyType,
  TitleVestingType,
  UnderwriterApprovalStatusType,
  WarehouseBankStatusType,
  WarehouseBankType
} from "../../../../types/entities/deal.enums.types";
import {
  DealQuote,
  QuoteType
} from "../../../../types/entities/deal.quote.types";
import { Deal } from "../../../../types/entities/deal.types";
import { BooleanSelectType } from "../../../../types/entities/fieldFormat.types";
import { LimaDealAggregations } from "../../../../types/entities/lima/deal/limaAggregations.types";
import {
  StateCode,
  StateName
} from "../../../../types/entities/location.types";
import {
  FloodInsurancePremiumPaymentType,
  PropertyInsurancePremiumPaymentType,
  PropertyRightsOwnershipType
} from "../../../../types/entities/property.enums.types";
import { IntegrationType } from "../../../../types/entities/task-types";
import { WireInsuranceVendorType } from "../../../../types/entities/task-types/integrations/wire-insurance-certificate";
import { removeBaseFields } from "./utils/entityTemplate.utils";

export const dealAggregationTemplate = () => {
  const template: DealAggregation & LimaDealAggregations = {
    ARMFinalAdjustableRateTermMonthCount: aggregationFieldTemplate(),
    ARMInitialFixedTermMonthCount: aggregationFieldTemplate(),
    ARMRepriceFrequencyType: aggregationFieldTemplate(),
    ScheduledFirstPaymentDate: aggregationFieldTemplate(),
    LastPaymentDueDate: aggregationFieldTemplate(),
    LoanMaturityDate: aggregationFieldTemplate(),
    FirstRateChangeDate: aggregationFieldTemplate(),
    PrepaymentPenaltyExpirationDate: aggregationFieldTemplate(),
    TotalBrokerFeeAmount: aggregationFieldTemplate(),
    TotalLTVRatePercent: aggregationFieldTemplate(),
    TotalLTARVRatePercent: aggregationFieldTemplate(),
    TotalHOAFeesAmount: aggregationFieldTemplate(),
    TotalInitialInsuranceEscrowAmount: aggregationFieldTemplate(),
    TotalAsIsAppraisedValueAmount: aggregationFieldTemplate(),
    TotalAssignmentFeeAmount: aggregationFieldTemplate(),
    TotalBudgetAmount: aggregationFieldTemplate(),
    TotalFloodInsurancePremiumAmount: aggregationFieldTemplate(),
    TotalInitialTaxEscrowAmount: aggregationFieldTemplate(),
    TotalOutstandingLoanPayoffAmount: aggregationFieldTemplate(),
    TotalAdjustedMonthlyRentAmount: aggregationFieldTemplate(),
    TotalAnnualCapitalExpenditure: aggregationFieldTemplate(),
    MonthlyPaymentOfCapitalExpenditure: aggregationFieldTemplate(),
    TotalPropertyInsurancePremiumAmount: aggregationFieldTemplate(),
    TotalPurchasePriceAmount: aggregationFieldTemplate(),
    TotalSubjectToAppraisedValueAmount: aggregationFieldTemplate(),
    TotalTaxAmount: aggregationFieldTemplate(),
    TotalCostAmount: aggregationFieldTemplate(),
    TotalFloodInsurancePremiumOutstandingAmount: aggregationFieldTemplate(),
    RequestedLoanAmount: aggregationFieldTemplate(),
    TotalMonthlyMarketRent: aggregationFieldTemplate(),
    NetWireAmount: aggregationFieldTemplate(),
    TotalPropertyInsurancePremiumOutstandingAmount: aggregationFieldTemplate(),
    PrepaidInterestAmount: aggregationFieldTemplate(),
    LoanProductType: thirdPartyFieldTemplate(),
    EstimatedClosingDate: thirdPartyFieldTemplate(),
    LoanProgramType: thirdPartyFieldTemplate(),
    TotalNumberOfProperties: aggregationFieldTemplate(),
    LastDayOfClosingMonth: aggregationFieldTemplate(),
    Integrations: {
      [IntegrationType.WireInsuranceCertificate]: {
        [WireInsuranceVendorType.FundingShield]: {
          StateNYIndicator: aggregationFieldTemplate(),
          TransactionId: thirdPartyFieldTemplate(),
          TransactionStatus: thirdPartyFieldTemplate()
        }
      }
    },
    PerDiemPrepaidInterestAmount: aggregationFieldTemplate()
  };
  return template;
};
export const dealQuoteTemplate = () => {
  const template: DealQuote = {
    quoteType: "" as QuoteType,
    aggregations: {
      // SHARED GET

      OriginationFeeAmount: thirdPartyFieldTemplate(),
      ProcessingFeeAmount: thirdPartyFieldTemplate(),
      BrokerOriginationFeeAmount: thirdPartyFieldTemplate(),
      ServicingSetupFee: thirdPartyFieldTemplate(),
      LenderFinanceProcessingFee: thirdPartyFieldTemplate(),
      LenderFinanceApplicationFee: thirdPartyFieldTemplate(),
      LimaRateToBorrowerPercent: thirdPartyFieldTemplate(),
      LimaOriginationFeeToBorrowerPercent: thirdPartyFieldTemplate(),
      PricingEngineWarnings: thirdPartyFieldTemplate(),
      BrokerProcessingFeeAmount: thirdPartyFieldTemplate(),
      FeeLLPAs: thirdPartyFieldTemplate(),
      RateLLPAs: thirdPartyFieldTemplate(),
      PricingEngineOverrides: thirdPartyFieldTemplate(),
      LenderFinanceDocumentFee: thirdPartyFieldTemplate(),
      LenderFinanceAppraisalFee: thirdPartyFieldTemplate(),
      ValidationErrors: thirdPartyFieldTemplate(),
      QuoteId: thirdPartyFieldTemplate(),
      AchRequiredIndicator: thirdPartyFieldTemplate(),
      AchExemptionStatus: thirdPartyFieldTemplate(),
      PricingInfo: thirdPartyFieldTemplate(),
      CampaignCode: thirdPartyFieldTemplate(),
      CampaignRateIncentive: thirdPartyFieldTemplate(),
      CampaignFeeIncentive: thirdPartyFieldTemplate(),
      CampaignLeverageIncentive: thirdPartyFieldTemplate(),
      CampaignExpirationDate: thirdPartyFieldTemplate(),
      // RENTAL GET
      LtvEligibility: thirdPartyFieldTemplate(),
      InterestReserveEscrow: thirdPartyFieldTemplate(),
      PrepaymentPenaltyType: thirdPartyFieldTemplate(),
      NoteRatePercent: thirdPartyFieldTemplate(),
      MonthlyPrincipalInterestPaymentAmount: thirdPartyFieldTemplate(),
      MonthlyTaxInsurancePaymentAmount: thirdPartyFieldTemplate(),
      MonthlyHomeownersAssociationAmount: thirdPartyFieldTemplate(),
      TotalMonthlyPITIPaymentAmount: thirdPartyFieldTemplate(),
      DebtServiceCoverageRatioPercent: thirdPartyFieldTemplate(),
      BuyDownFeeAmount: thirdPartyFieldTemplate(),
      BuyUpFeeReductionAmount: thirdPartyFieldTemplate(),
      Margin: thirdPartyFieldTemplate(),
      FloorPercent: thirdPartyFieldTemplate(),
      LifetimeCapPercent: thirdPartyFieldTemplate(),
      DeferredOriginationFeeAmount: thirdPartyFieldTemplate(),

      // RENTAL POST
      scenario: {
        scenario: [
          {
            scenarioId: thirdPartyFieldTemplate()
          }
        ]
      },
      UnderPropertyValuationAmount: aggregationFieldTemplate(),
      UnderPropertyValuationTotalCost: aggregationFieldTemplate(),
      PrimaryBorrowerCreditScore: aggregationFieldTemplate(),
      StateNY: aggregationFieldTemplate(),
      InternalL1CRefinanceIndicator: aggregationFieldTemplate(),
      BorrowingEntity: aggregationFieldTemplate(),
      OverPropertyValuationAmount: aggregationFieldTemplate(),
      TotalValuationOrPurchasePriceAmount: aggregationFieldTemplate(),
      PropertyTypeCondoIndicator: aggregationFieldTemplate(),
      PropertyValuationAmountUnderIndicator: aggregationFieldTemplate(),
      USCitizenshipIndicator: aggregationFieldTemplate(),
      SeasonalRental: aggregationFieldTemplate(),
      PropertyTypeMultifamilyIndicator: aggregationFieldTemplate(),
      // SHORT-TERM GET
      InitialMonthlyInterestOnlyPaymentAmount: thirdPartyFieldTemplate(),
      PricingEngineLTARVRatePercent: thirdPartyFieldTemplate(),
      PricingEngineTotalLoanAmount: thirdPartyFieldTemplate(),
      FullyDrawnInterestOnlyPayment: thirdPartyFieldTemplate(),
      PricingEngineTotalLoanToAfterRepairValueOverridePercent:
        thirdPartyFieldTemplate(),
      PricingEngineTotalBlendedLoanToCostOverridePercent:
        thirdPartyFieldTemplate(),
      PricingEngineTotalPurchaseLoanToCostOverridePercent:
        thirdPartyFieldTemplate(),
      PricingEngineNoteRateOverridePercent: thirdPartyFieldTemplate(),

      PricingEngineMaxLoanToAfterRepairValuePercent: thirdPartyFieldTemplate(),
      PricingEngineMaxBlendedLoanToCostPercent: thirdPartyFieldTemplate(),
      PricingEngineMaxPurchaseLoanToCostPercent: thirdPartyFieldTemplate(),
      BlendedLTCAdjuster: thirdPartyFieldTemplate(),
      PurchaseLTCAdjuster: thirdPartyFieldTemplate(),
      ARVAdjuster: thirdPartyFieldTemplate(),

      // SHORT-TERM POST
      PrimaryGuarantor: aggregationFieldTemplate(),
      CompletedExits: aggregationFieldTemplate(),
      SumTotalStatementQualifyingBalance: aggregationFieldTemplate(),
      AltTier: aggregationFieldTemplate(),
      LesserOfLotOrPurchase: aggregationFieldTemplate(),
      DebtStatus: aggregationFieldTemplate(),
      PropertyAddressLineText: aggregationFieldTemplate(),
      PropertyCityName: aggregationFieldTemplate(),
      PropertyStateCode: aggregationFieldTemplate(),
      PropertyPostalCode: aggregationFieldTemplate(),
      PropertyType: aggregationFieldTemplate(),
      PropertyId: aggregationFieldTemplate(),
      OriginalPurchaseDate: aggregationFieldTemplate(),
      BorrowerTier: aggregationFieldTemplate()
    }
  };
  return template;
};

/** @deprecated
 * Use the customer-specific template
 */
export const getDealTemplate = (): Deal => {
  const deal: Deal = {
    id: "",
    index: "",
    createdAt: "",
    modifiedAt: "",
    aggregations: dealAggregationTemplate(),
    quote: dealQuoteTemplate(),
    LetterOfIntentSignedDate: "",
    OperationsDepartment: "",
    PortfolioLoanIndicator: "" as BooleanSelectType,
    SecondaryBorrowers: [],
    PrimaryBorrowers: [],
    PrimarySponsors: [],
    SecondarySponsors: [],
    LOCAvailableAmount: "",
    LOCExpirationDate: "",
    CashOutAmount: "",
    DownPaymentAmount: "",
    AggregateOccupancyPercent: "",
    PolicySectionNumber: "",
    LenderIdentifier: "" as LenderIdentifierType,
    LoanIdentifier: "",
    ApplicationReceivedDate: "",
    LOCIssuanceDate: "",
    PreliminaryUnderwritingDecisionDate: "",
    FinalUnderwritingDecision: "",
    InitialTaskGenerationDate: "",
    ClearToCloseDate: "",
    LoanName: "",
    Channel: "" as ChannelType,
    RealEstateProgramType: "" as RealEstateProgramType,
    EmployeeLoanIndicator: "",
    TotalPropertyCount: "",
    RecourseType: "" as RecourseType,
    WireReleaseDate: "",
    LoanFundingDate: "",

    PaymentDueDay: "" as PaymentDueDayType,
    NextPaymentDueDate: "",
    AmortizationType: "" as AmortizationType,
    ExecutionType: "" as ExecutionType,
    LoanPaymentType: "" as LoanPaymentType,
    LoanTermPeriodMonthCount: "",
    LoanAmortizationPeriodMonthCount: "",
    LoanInterestOnlyPeriodMonthCount: "",
    LoanAfterInterestOnlyPeriodMonthCount: "",
    InterestAccrualMethodType: "" as InterestAccrualMethodType,
    LoanAmortizationPeriodYearCount: "",

    IndexSourceType: "" as IndexSourceType,
    CapitalStructureType: "" as CapitalStructureType,
    MarginRatePercent: "",
    FloorRatePercent: "",
    LifetimeCapRatePercent: "",
    ARMRepriceRoundingRatePercentType: "" as ARMRepriceRoundingRatePercentType,
    ARMRepriceRoundingMethodType: "" as ARMRepriceRoundingMethodType,
    ARMRepriceLookbackType: "" as ARMRepriceLookbackType,

    UCCFiledIndicator: "" as BooleanSelectType,
    UCCExpirationDate: "",
    DefaultInterestRatePercent: "",
    WarehouseBankType: "" as WarehouseBankType,
    WarehouseBankStatusType: "" as WarehouseBankStatusType,
    FundingShieldStatusType: "" as FundingShieldStatusType,
    HaircutAmount: "",
    PostClosingNotes: "",
    CollateralPackageStatusType: "" as CollateralPackageStatusType,
    CollateralPackageTrackingNumber: "",
    Ledger: {
      AppraisalDepositAmount: "",
      AppraisalFeeAmount: "",
      CreditReportFeeAmount: "",
      InterestReserveAmount: "",
      NewYorkProcessingFeeAmount: "",
      CondoCertificationFeeAmount: "",
      ContractorReviewFeeAmount: "",
      FeasibilityReviewFeeAmount: "",
      LenderCounselFeeAmount: ""
    },
    AllongeOrAssignmentSentToWarehouseIndicator: "" as BooleanSelectType,
    BlendedLTCLoanAmount: "",
    Broker: {
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullName: "",
      NMLSIdentifier: "",
      RepresentativeFirstName: "",
      RepresentativeLastName: "",
      BrokerWiringInstructionsIndicator: "" as BooleanSelectType
    },
    ClosingAnalystUserId: "",
    ConstructionLTCRatePercent: "",
    GeneralContractor: {
      CapitalExpenditureAmount: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullName: "",
      RepresentativeFirstName: "",
      RepresentativeLastName: ""
    },
    InitialAdvancedFundAmount: "",
    LienPosition: "",
    MaxARVAmount: "",
    PersonalGuarantorsCount: "",
    PowerOfAttorneyFullName: "",
    Integrations: {
      [IntegrationType.WireInsuranceCertificate]: {
        [WireInsuranceVendorType.FundingShield]: {
          userFirstName: "",
          userLastName: "",
          userEmail: "",
          rushOrderIndicator: BooleanSelectType.false
        }
      }
    },
    PropertyModelType: "" as PropertyModelType,
    SettlementStatementFileNumber: "",
    ShippingCompanyType: "" as ShippingCompanyType,
    NoteToWarehouseTrackingNumber: "",
    TitleCompany: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      ClosingAgentContactPointEmailValue: "",
      ClosingAgentContactPointTelephoneValue: "",
      FullName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName,
      CountyName: "",
      ClosingAgentFirstName: "",
      ClosingAgentLastName: "",
      TitleInsuranceUnderwriter: "",
      Attorney: {
        FullName: ""
      }
    },
    TotalLoanFeesAndClosingCostAmount: "",
    TotalPropertyValuationAmount: "",
    PromissoryNoteComments: "",
    PropertyManagement: {
      PropertyManagerType: "" as PropertyManagerType,
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullAddressName: "",
      FullName: "",
      RepresentativeFullName: "",
      PropertyManagementComments: ""
    },
    DealMetadata: {
      milestone: "" as DealMilestoneType,
      activeUsers: [],
      branches: [],
      organizations: [],
      teams: []
    },
    IntegrationMetadata: {
      customerPortalId: "",
      salesforceAccountId: "",
      salesforceDealId: ""
    },
    Exceptions: {
      ExceptionApprovedBy: "",
      ExceptionCommentary: "",
      ExceptionType: [],
      PolicySection: []
    },
    GracePeriodDays: "",
    GrossSpreadPercent: "",
    LastPaymentDueDate: "",
    LateFeePercent: "",
    Lender: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      CountyName: "",
      EntityType: "" as LenderEntityType,
      FormationState: "" as StateName,
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    ReleasePercent: "",
    PromissoryNoteRequestedIndicator: "" as BooleanSelectType,
    PromissoryNoteShippedIndicator: "" as BooleanSelectType,
    PromissoryNoteTrackingNumber: "",
    AllongeAssignmentSentToWarehouseIndicator: "" as BooleanSelectType,
    AllongeAssignmentTrackingNumber: "",
    NotaryUserId: "",
    NotaryExpirationDate: "",
    LoanPackageTrackingNumber: "",
    LoanPackageNoteTrackingNumber: "",
    FundingAndDisbursementApprovalIndicator: "" as BooleanSelectType,
    LoanPackageInternationalExecutionIndicator: "" as BooleanSelectType,
    LoanPackageNoteReceivedByLenderIndicator: "" as BooleanSelectType,
    LoanPackageChecksReceivedIndicator: "" as BooleanSelectType,
    LoanPackageCheckNumber: "",
    LoanPackageCheckAmount: "",
    UnderwriterApprovalStatus: "" as UnderwriterApprovalStatusType,
    UnderwriterApprovalComments: "",
    AssetSummaryReportIndicator: "" as BooleanSelectType,
    CreditMemorandumIndicator: "" as BooleanSelectType,
    CreditReviewCommitteeApprovalIndicator: "" as BooleanSelectType,
    ProgramDirectorApprovalIndicator: "" as BooleanSelectType,
    ProgramDirectorComments: "",
    SubmissionNotes: "",
    PricingEngineExceptionStatus: "" as PricingEngineExceptionStatusType,
    ChargentCollectionAmount: "",
    ChargentGatewayIdentifier: "",
    CRMId: "",
    ChargentTransactionIdentifier: "",
    ConstructionBudgetVerifiedMatchIndicator: "" as BooleanSelectType,
    DeferredMaintenanceAmount: "",
    EOExpirationDate: "",
    EONamedInsuredMatchIndicator: "" as BooleanSelectType,
    FloodInsuranceAgency: {
      FullName: ""
    },
    FloodInsurancePremiumPaymentType: "" as FloodInsurancePremiumPaymentType,
    InitialLTVRatePercent: "",
    InterestReserveDepositAmount: "",
    LoanAmountVerifiedMatchIndicator: "" as BooleanSelectType,
    PropertyInsuranceAgency: {
      FullName: ""
    },
    PropertyInsurancePremiumPaymentType:
      "" as PropertyInsurancePremiumPaymentType,
    SettlementStatementComments: "",
    SettlementStatementMatchLedgerIndicator: "" as BooleanSelectType,
    TitleCommitment: {
      TitleExpirationDate: "",
      PropertyRightsOwnershipType: "" as PropertyRightsOwnershipType,
      AllPayoffsLiensIncludedInSection1RequirementsIndicator:
        "" as BooleanSelectType,
      AllSubjectPropertiesIncludedIndicator: "" as BooleanSelectType,
      LeaseholdEndorsementIndicator: "" as BooleanSelectType,
      RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator:
        "" as BooleanSelectType,
      TitleCommitmentComments: "",
      TitleEffectiveDate: "",
      TitleInternationalExecutionIndicator: "" as BooleanSelectType,
      TitlePolicyAmount: "",
      TitleVestingType: "" as TitleVestingType
    },
    TitleFormsComments: "",
    TotalLiquidAssetAmount: "",
    ValuationFeesCollectedBySalesIndicator: "" as BooleanSelectType,
    WireABARoutingNumber: "",
    WireAccountName: "",
    WireAccountNumber: "",
    WireBankName: "",
    LoanPurposeType: "" as LoanPurposeType,
    RateLockStartDate: "",
    RateLockEndDate: "",
    TotalCashToFromBorrower: "",
    CashFromBorrowerAmount: "",
    CashToBorrowerAmount: "",
    BuildingEnvelopeExpandedIndicator: "" as BooleanSelectType,
    LoadBearingWallsRemoveIntendedIndicator: "" as BooleanSelectType,
    SquareFootageAddedIndicator: "" as BooleanSelectType,
    InterestReserveEscrowOverrideMonthCount: "",
    InterestReserveEscrowOverrideAmount: "",
    ProcessingFeeOverride: "",
    LenderFinance: {
      PartnerFirstName: "",
      PartnerLastName: "",
      PartnerInitialTermSheetApprovalStatusIndicator: "" as BooleanSelectType,
      PartnerInitialTermSheetApprovalStatusDate: "",
      PartnerFinalTermSheetApprovalStatusDate: "",
      BorrowerFinalTermSheetApprovalStatusDate: ""
    },
    ExitStrategy: "",
    TargetCloseDate: "",
    ApplicationRequestedLoanAmount: "",
    ProjectAnticipatedCompletionTimeline: "",
    ConstructionBudgetRemainingAmount: "",
    VerifiableConstructionCompletedAmount: "",
    LienDollarAmount: "",
    PropertyTypeChangingIndicator: "" as BooleanSelectType,
    PropertyZoningChangesIndicator: "" as BooleanSelectType,
    SubdividePropertyPartialReleasesIndicator: "" as BooleanSelectType,
    LenderTitleProviderIndicator: "" as BooleanSelectType,
    LenderPropertyInsuranceProviderIndicator: "" as BooleanSelectType,
    HOAContactName: "",
    HOAContactPhone: "",
    HOAContactEmail: "",
    Occupancy: "",
    PropertyRentReadyConditionIndicator: "" as BooleanSelectType,
    PropertyLeasePurchaseOptionIndicator: "" as BooleanSelectType,
    OccupationIndicator: "" as BooleanSelectType,
    SellerRelationshipIndicator: "" as BooleanSelectType,
    AssociatedPropertiesDescription: "",
    DevelopmentStrategy: "",
    LotStatus: "",
    CurrentLotMarketValueAmount: "",
    LotZonedParcelIdIndicator: "" as BooleanSelectType,
    BuildingPermitsIndicator: "" as BooleanSelectType,
    BuildingPermitsExpectedDate: "",
    LotUtilitiesIndicator: "" as BooleanSelectType,
    CreditReviewCommittee: {
      creditMemoDraftedDate: "",
      creditMemoApprovedDate: "",
      internalSubmissionDate: "",
      internalApprovalDate: "",
      externalSubmissionDate: "",
      externalApprovalDate: "",
      lOIIssuedDate: "",
      lOIAcceptedDate: ""
    },
    ACHElectedIndicator: "" as BooleanSelectType,
    SellerHUDAddedIndicator: "" as BooleanSelectType,
    PurchaserWarrantyDeedAddedIndicator: "" as BooleanSelectType,
    mailAwayIndicator: "" as BooleanSelectType,
    fundingAgentEmailAddress: "",
    WireInsuranceMatchDealIndicator: "" as BooleanSelectType,
    RecordedMortgageReceivedDate: "",
    TitlePolicyReceivedDate: "",
    RecordedWarrantyDeedReceivedDate: "",
    AppraisalNotes: ""
  };

  return deal;
};

/** @deprecated
 * Use the customer-specific template
 */
export const getDealTemplateOmitCreate = (): OmitCreate<Deal> => {
  const property: Deal = getDealTemplate();
  return removeBaseFields({
    entity: property,
    operationType: "create"
  }) as OmitCreate<Deal>;
};

/** @deprecated
 * Use the customer-specific template
 */
export const getDealTemplateOmitUpdate = (): OmitUpdate<Deal> => {
  const property: Deal = getDealTemplate();
  return removeBaseFields({
    entity: property,
    operationType: "update"
  }) as OmitUpdate<Deal>;
};
